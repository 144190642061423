class PreCertificada{
    IdAfiliado= 1
    Cedula= "" 
    NombrePss= "" 
    RazonSolicitud= "" 
    FechaServicio= "" 
    Telefono= "" 
    IdSolicitudPrecertificada= 0 
    CC= "" 
    CAR= "" 
    IM= "" 
    HC= "" 
    OSC= "" 
}

export default PreCertificada