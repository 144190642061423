
import {PrestadorModeloConsulta} from '../modelos/PrestadorModeloConsulta'
import Enlazar from '../utilidades/Enlazar'
import Validador from '../utilidades/Validador'
import GeneralRepositorio from "../repositorios/GeneralRepositorio"
import {alerta} from '../utilidades/SinClasificar'
import Persona from '../modelos/Persona'
import Empresa from '../modelos/Empresa'
declare var $:any
export default class IndexControlador {
    enlazar:Enlazar
    generalRepositorio:GeneralRepositorio
    validador:Validador
    constructor() {
        this.enlazar = new Enlazar()
        this.generalRepositorio = new GeneralRepositorio()
        this.validador = new Validador()
     }
     async enlazarConsultaPadronByCedula(Cedula:string=""){
        const enlazar = (persona: any) => {
            if (persona.Cedula) {
               var d = new Date(persona.Fechnac);
               $("#Nombres").val(persona.Nombre1);
               $("#Apellidos").val(`${persona.Apellido1} ${persona.Apellido2}`);
               $("#FechaNacimientoStr").val(`${("0" + (d.getDate())).slice(-2)}-${("0" + (d.getMonth() + 1)).slice(-2)}-${d.getFullYear()}`);
               $('#Sexo').val(persona.Sexo);
               $('#IdEstadoCivil').val(persona.ESTACIVIL == 'S' ? 2:1)
            }
        }

        await this.generalRepositorio.obtenerDatosByCedula(Cedula, enlazar)
    }
    async enlazarConsultaPadronByRNC(RNC:string=""){
        const enlazar = (empresa: Empresa) => {
            if (empresa.RNCEMPR) {
               $("#RAZONSC").val(empresa.RAZONSC);
            }
        }
        await this.generalRepositorio.obtenerDatosByRNC(RNC, enlazar)
    }
    buscarPSS() {
        let pss = this.enlazar.enlazarInputObjeto(new PrestadorModeloConsulta()) as PrestadorModeloConsulta
        // let validacion = this.validador.validarCampos(pss, ["Nombre"])
        // if(validacion.length == 0){
            window.location.href = `pssbusqueda.html?idtipo=${pss.IdTipo}&idprovincia=${pss.IdProvincia}&nombre=${pss.Nombre}`
        // }else{
        //     alerta("Debe incluir el nombre en la busqueda.")
        // } 


        return false
    }
    enlazarRegion(selector:string="IdAreaSalud"){
        const handler = (response:any) =>{
            this.enlazar.enlazarListaPlantilla(response, "plantillaSelectGeneric", selector)
        }
        this.generalRepositorio.obtenerRegiones(handler);
    }
    enlazarProvincias(IdAreaSalud:number=null,selector:string="IdProvincia"){
        const handler = (response:any) =>{
            this.enlazar.enlazarListaPlantilla(response, "plantillaSelectProvincias", selector)
        }
        this.generalRepositorio.obtenerProvincias(handler,IdAreaSalud);
    }
    enlazarMunicipios(IdProvincia:number=null,selector:string="IdMunicipio"){
        const handler = (response:any) =>{
            this.enlazar.enlazarListaPlantilla(response, "plantillaSelectMunicipios", selector)
        }
        this.generalRepositorio.obtenerMunicipios(IdProvincia,handler)
    }

    enlazarTiposPss(selector:string="IdTipo"){
        const handler = (response:any) =>{
            this.enlazar.enlazarListaPlantilla(response, "plantillaSelectTipoPsss",selector )
        }
        this.generalRepositorio.obtenerTiposPss(handler,null)
    }
    enlazarGrupoPss(IdClasificacion:number,Selector:string="IdGrupo"){

        const handler = (response:any) =>{
            this.enlazar.enlazarListaPlantilla(response, "plantillaSelectGrupoPss", Selector)
        }
        this.generalRepositorio.obtenerTiposPss(handler,IdClasificacion)
    }
    enlazarSexo(selector:string="Sexo"){
        const handler = (response:any) =>{
            this.enlazar.enlazarListaPlantilla(response, "plantillaSelectGeneric", selector)
        }
        this.generalRepositorio.obtenerSexo(handler);
    }
    enlazarNacionalidades(selector:string="IdNacionalidad"){
        const handler = (response:any) =>{
            this.enlazar.enlazarListaPlantilla(response, "plantillaSelectNacionalidad", selector)
        }
        this.generalRepositorio.obtenerNacionalidades(handler);
    }
    enlazarEstadosCiviles(selector:string="IdEstadoCivil"){
        const handler = (response:any) =>{
            this.enlazar.enlazarListaPlantilla(response, "plantillaSelectEstadoCivil", selector)
        }
        this.generalRepositorio.obtenerEstadosCiviles(handler);
    }
    enlazarTitulos(selector:string=null){
        const handler = (response:any) =>{
            this.enlazar.enlazarListaPlantilla(response, "plantillaSelectTitulo", selector)
        }
        this.generalRepositorio.obtenerTitulos(handler);
    }
    
}