declare var swal:any
declare var HideLoader:() => void;
declare var JSON:any
export const baseURl = "https://webapimetasalud.com/";
// export const baseURl = "http://localhost:52982/";


export function formatNumber(n, c = undefined, d=undefined, t=undefined) {
    var c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;

    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};


export function getFechaNacimiento(date:string=""):Date
{
    return new Date(
        parseInt(date.split("-")[2]),
        parseInt(date.split("-")[1])-1,
        parseInt(date.split("-")[0]))
};

export function alerta(msj:string, titulo:string = '',type:SwalType=SwalType.INFO,){
    return swal({
        title:titulo,
        text:msj,
        icon:type
    })
}
export function confirmar(msj:string, titulo:string = '',type:SwalType,btnConfirm:string="Confirmar"){
    const response = swal({
        title: titulo,
        text: msj,
        icon: type,
        buttons:["Cancelar",btnConfirm]
    }).then(function (result:any) {
        return result;
    });
    return response;
}

export function formatToPercentage(obj:any, campos:any):any {
    if (!(campos instanceof (Array))) {
        campos = Object.keys(campos)
    }

    campos.forEach(function (c:any,k:number) {
        obj[c] = `${obj[c]}%`;
    })
    return obj
}
export function defaultHttpErrorHandler(response: any) {
    //if(HideLoader)HideLoader();
    alerta(JSON.parse(response).Message,null,SwalType.WARNING);
}


export function getQueryVariable(variable:any) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    console.log('Query variable %s not found', variable);
}

export enum SwalType{
     INFO='info'
    ,ERROR='error'
    ,SUCCESS='success'
    ,WARNING='warning'
    // QUESTION='question'
}
export function clearStorageCookies() {
    sessionStorage.clear();
    document.cookie = "token=;"
    document.cookie = "identificacion=;"
    window.location.href = "index.html"
}

export function getCookie(name:string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }