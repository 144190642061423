import PrestadorLogin from '../modelos/prestadorLogin'
import PrestadorRepositorio from '../repositorios/prestadorRepositorio'
import Enlazar from '../utilidades/Enlazar'
import { alerta, getQueryVariable, formatNumber, confirmar, SwalType,clearStorageCookies } from '../utilidades/SinClasificar'
import registroPrestador from '../modelos/registroPrestador'
import Validador from '../utilidades/Validador'
import { PrestadorModeloConsulta } from '../modelos/PrestadorModeloConsulta';
import PrestadorContrasena from '../modelos/prestadorContransena'
import { reestablecerContrasenaPss } from '../modelos/reestablecerContrasena'
import LoteWebSite from '../modelos/LoteWebSite'
import Procedimientos from '../modelos/Procedimientos';
import GuardarPrecioPss from '../modelos/GuardarPrecioPss'
import Centro from '../modelos/Centro'
import Persona from '../modelos/Persona'
import GeneralRepositorio from '../repositorios/GeneralRepositorio'
import CentroView from '../modelos/CentroView'
import Medico from '../modelos/Medico'
import MedicoCentro from '../modelos/MedicoCentro'
import {getFechaNacimiento} from '../utilidades/SinClasificar'
import TelefonoMedico from '../modelos/TelefonoMedico'
import TelefonoCentro from '../modelos/TelefonoCentro'
import Representante from '../modelos/Representante'
import MedicoEspecialidad from '../modelos/MedicoEspecialidad'
import {EnumTipoSolicitudPSS} from '../modelos/EnumClasses'
declare var JSON: any
declare var $: any
declare var HideLoader: any
 

export default class PrestadorControlador {
    repositorio: PrestadorRepositorio
    generalRepo: GeneralRepositorio
    enlazar: Enlazar
    validador: Validador
    constructor() {
        this.enlazar = new Enlazar();
        this.repositorio = new PrestadorRepositorio()
        this.validador = new Validador()
        this.generalRepo = new GeneralRepositorio();
    }

    login() {
        const prestadorLogin = new PrestadorLogin()
        const loginModel = this.enlazar.enlazarInputObjeto(prestadorLogin) as PrestadorLogin

        const handler = (response: any): any => {
            //crear cookie y sesion
            if (!response) return null;
            let prestador = response;

            if (sessionStorage.getItem('prestador'))
                sessionStorage.clear();

            sessionStorage.setItem('prestador', JSON.stringify(prestador))
            sessionStorage.setItem('tokprestadoren', "")
            //set cookie
            document.cookie = "token=;"
            document.cookie = "identificacion=" + prestador.RncoCedula + ";"
            window.location.href = "indexPss.html"
        }

        this.repositorio.iniciarSesion(loginModel, handler)
    }

    async revisarSesion() {
        let prestador = JSON.parse(sessionStorage.getItem('prestador'))
        if (!prestador) window.location.href = "loginPrestadores.html"
        this.enlazar.enlazarObjetoValor("nombreCabecera", prestador,".")

    }
    async cerrarSesion(){
        clearStorageCookies()
    }

    validarYRegistrar() {
        const nuevoPrestador = this.enlazar
            .enlazarInputObjeto(
            new registroPrestador()) as registroPrestador
        const camposValidar: any = ["IdPss", "RncCedula", "Contrasena",
            "CedulaRepresentante", "Correo"]

        const contrasenaConf = $("#confContrasena").val()

        if (!this.validador.validaContrasena(nuevoPrestador.Contrasena, contrasenaConf)) {
            return;
        }

        let camposInvalidos = this.validador.validarCampos(nuevoPrestador, camposValidar)
        if (camposInvalidos.length == 0) {
            this.repositorio.registrar(nuevoPrestador, this.prestadorRegistrado)
        } else {
            alerta("Debe llenar los campos marcados en rojo","Advertencia",SwalType.WARNING)
        }
    }

    enlazarDatosPrestador() {
        let prestador = JSON.parse(sessionStorage["prestador"])
        this.enlazar.enlazarObjetoInput(prestador)

    }

    consultarPrestador() {
        let modeloConsulta = this.enlazar
            .enlazarInputObjeto(new PrestadorModeloConsulta()
            ) as PrestadorModeloConsulta
        const handler = (response: any) => {
            if (response && response.length >= 0) {
                enlazarPrestadores(response)
            }
        }

        this.repositorio.obtenerPrestadorConsulta(modeloConsulta, handler)
    }

    cargarBusquedaPss() {
        if (window.location.search.includes("nombre")) {
            let modelo = new PrestadorModeloConsulta()
            modelo.Nombre = getQueryVariable("nombre")
            modelo.IdProvincia = getQueryVariable("idprovincia")
            modelo.IdTipo = getQueryVariable("idtipo")

            if (modelo.IdProvincia =="0") modelo.IdProvincia = "null"
            if (modelo.IdTipo =="0") modelo.IdTipo = "null"
            if (modelo.Nombre =="" ||modelo.Nombre ==null) modelo.Nombre = "null"
            const handler = (response: any) => {
                if (response && response.length >= 0) {
                    enlazarPrestadores(response)
                }
            }

            this.repositorio.obtenerPrestadorConsulta(modelo, handler)
        }
    }

    prestadorRegistrado(response: any) {
        window.location.href = "registrado.html"
    }

    cambiarContrasena() {
        let contrasenas = this.enlazar.enlazarInputObjeto(new PrestadorContrasena()) as PrestadorContrasena
        let prestador = JSON.parse(sessionStorage["prestador"])
        contrasenas.IdPss = prestador.IdPss

        if (this.validador.validarCampos(contrasenas, contrasenas).length > 0) {
            alerta("Llene los campos faltantes","Advertencia",SwalType.WARNING)
            return;
        }
        if (this.validador.validaContrasena(contrasenas.ContrasenaConfirmacion,
            contrasenas.ContrasenaConfirmacion)) {
            const handler = (respose: any) => {

            }


            this.repositorio.cambiarContrasena(contrasenas, handler)
        }

    }

    enlazarHistorialReclamacionesPss() {
        const pss = JSON.parse(sessionStorage["prestador"])
        const handler = (response: any) => {
            if (response) {
                this.enlazar.enlazarListaPlantilla(response, "PlantillaHistorialReclamaciones", "historialReclamaciones")
            }
        }

        this.repositorio.obtenerHistorialReclamaciones(pss.RncCedula, handler)
    }

    async enlazarDatosTarifa(){
        const pss = JSON.parse(sessionStorage["prestador"]);
        const IdPrestador = pss.IdPss;
        
        const enlazar = ( Procedimientos: any ) =>{
            if(Procedimientos == undefined){
                $("#bt_GuardarTarifa").attr("style", "display:none")
            }
            if(Procedimientos !== undefined &&  Procedimientos.PreTarifas != null)
                if(Procedimientos.PreTarifas.length > 0 && Procedimientos.PreTarifas[0].EstadoCorto == "Negociado")
                    $("#bt_GuardarTarifa").attr("style", "display:none")
           
           if(Procedimientos !== undefined && Procedimientos.PreTarifas.length > 0){
               let Estatus:string = "";
               switch(Procedimientos.PreTarifas[0].EstadoCorto){
                   case "NoNegociado":
                       Estatus = "No Negociada";
                       break;
                    case "NuevaPropuestaArs":
                        Estatus  = "Nueva Propuesta ARS";
                        break;
                    case "NuevaPropuestaPss":
                        Estatus = "Nueva Propuesta PSS";
                        break;
                    default:
                        Estatus = `${Procedimientos.PreTarifas[0].EstadoCorto}`;
                        break;
               }
               $("#EstatusTarifa").append(`(${Estatus})`)
               //Formating Estatus into detail
               for(let procedimiento of Procedimientos.Detalle){
                switch(procedimiento.Estado){
                    case "NoNegociado":
                        procedimiento.Estado = "No Negociada";
                        break;
                     case "NuevaPropuestaArs":
                        procedimiento.Estado = "Nueva Propuesta ARS";
                         break;
                     case "NuevaPropuestaPss":
                        procedimiento.Estado = "Nueva Propuesta PSS";
                         break;
                     default:
                        procedimiento.Estado = "Negociado";
                         break;
                }
               }
           }
            if(Procedimientos != undefined){
                let detalle = Procedimientos.Detalle;
                detalle.sort((first, second)=> first.Estado > second.Estado ? -1 : 0 )

                Procedimientos = detalle.map((a: any) => {
                return {
                    CodSimon: a.CodSimon,
                    Procedimiento: a.Procedimiento,
                    PrecioArs: formatNumber(a.PrecioArs),
                    PrecioPss: a.PrecioPss,
                    Estado: a.Estado,
                    Opciones:(a.Estado == "Negociado")? 
                            `<a 
                                class="button BtNegoceado" disabled
                                onmouseover='this.style.color ="#00aba5"' 
                                onmouseout ='this.style.color ="#fff"'
                                style="cursor: no-drop;color:#fff;">
                                Cambiar precio
                            </a>`
                            : (a.Estado == "NuevaPropuestaPss")?
                            `<a 
                                onmouseover='this.style.color ="#00aba5"' 
                                onmouseout ='this.style.color ="#fff"'
                                style="color:#fff;"
                                class="button BtnNuevaPropuestaPss" disabled>
                                Cambiar precio
                            </a>`
                                :`<a 
                                    data-codsimon="${a.CodSimon}"
                                    data-procedimiento="${a.Procedimiento}"
                                    data-precioars="${a.PrecioArs}"
                                    data-preciopss="${a.PrecioPss}" 
                                    data-estado="${a.Estado}"
                                    data-codmetasalud="${a.CodMetasalud}"
                                    class="button BtnModificarPrecio" 
                                    onmouseover='this.style.color ="#00aba5"' 
                                    onmouseout ='this.style.color ="#fff"'
                                    style="color:#fff;"
                                    >
                                    Cambiar precio
                                </a>`
                }
            })
                $(".dataTables_length").empty()
                $(".dataTables_filter").empty()
                $(".dataTables_info").empty()
                $(".dataTables_paginate").empty()
        
                $('#TablaProcedimientosTarifa').DataTable({
                "language": {
                    "sProcessing": "Procesando...",
                    "sLengthMenu": "Mostrar _MENU_ registros",
                    "sZeroRecords": "No se encontraron resultados",
                    "sEmptyTable": "Ningún dato disponible en esta tabla",
                    "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
                    "sInfoPostFix": "",
                    "sSearch": "Buscar:",
                    "sUrl": "",
                    "sInfoThousands": ",",
                    "sLoadingRecords": "Cargando...",
                    "oPaginate": {
                        "sFirst": "Primero",
                        "sLast": "Último",
                        "sNext": "Siguiente",
                        "sPrevious": "Anterior"
                    },
                    "oAria": {
                        "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
                        "sSortDescending": ": Activar para ordenar la columna de manera descendente"
                    }
                },
                data: Procedimientos,
                columns: [
                    { data: 'CodSimon' },
                    { data: 'Procedimiento' },
                    { data: 'PrecioArs' },
                    { data: 'PrecioPss' },
                    { data: 'Estado' },
                    { data: 'Opciones' }
                ],
                ordering:false
            });
                $('#TablaProcedimientosTarifa').attr("style", "width:100% !important")

                //Set Events to Buttons of the options (Cambiar Valor)
                var el = document.querySelectorAll('.BtnModificarPrecio');
                for(var i=0; i < el.length; i++){
                    el[i].addEventListener('click', this.CambiarPrecioBtnClickHandler, false);
                }

                var elbt_GetAllPropuestaPss = document.querySelectorAll(".BtnNuevaPropuestaPss");
                for(var i=0; i < elbt_GetAllPropuestaPss.length; i++){
                    elbt_GetAllPropuestaPss[i].addEventListener('click', this.EsperandoPorPropuestaArs, false);
                }
            }
        }
        this.repositorio.obtenerTarifaPrestador(IdPrestador, enlazar);
    }

    /**
     * Events of Button Modificar precio Pss 
     */
    CambiarPrecioBtnClickHandler(){
        $("#ModalCodSimon").val(this.dataset.codsimon);
        $("#ModalCodMetaSalud").val(this.dataset.codmetasalud);
        $("#ModalEstado").val(this.dataset.estado);
        $("#ModalProcedimiento").val(this.dataset.procedimiento);
        $("#ModalPrecioArs").val(this.dataset.precioars);
        $("#ModalPrecioPss").val(this.dataset.preciopss);
        $("#Modal_ModificarPrecioPss").modal("show");
    }

    EsperandoPorPropuestaArs(){
        alerta("Debe esperar la nueva propuesta por parte de la ARS.","Aviso",SwalType.INFO);
    }

    /**
     * Guardar los nuevos precios de los prestadores
     */
    async GuardarCambiosProcedimientos(){
        const pss = JSON.parse(sessionStorage["prestador"]);
        const IdPrestador = pss.IdPss;
        let PrecioPss =  $("#ModalNuevoPrecioPss").val();
        let CodMetasalud = $("#ModalCodMetaSalud").val();
        
        let modeloPrecio = new GuardarPrecioPss();
        modeloPrecio.IdPrestador = IdPrestador;
        modeloPrecio.CodMetasalud = CodMetasalud;
        modeloPrecio.PrecioPss = PrecioPss;

        function handler(response: any) {
            alerta("Se envio la solicitud de la nueva propuesta!","Informacion",SwalType.INFO)
            .then(() => {
                location.reload();
            })
        }

        await this.repositorio.guardarPrecioProcedmiento(modeloPrecio, handler)
    }

    async EnviarNotificacionNuevosCambiosTarifa() {
        const pss = JSON.parse(sessionStorage["prestador"]);
        const IdPrestador = pss.IdPss;
        function handler(response: any) {
            
            alerta("Se envio la solicitud de la nueva propuesta de cambios en la tarifa!","Aviso",SwalType.INFO)
            .then(() => {
                location.reload();
            })
        }
        this.repositorio.EnviarNotificacionNuevosCambiosTarifa(IdPrestador, handler)
    }


    async editar() {
        $(".editable").each(function () {
            $(this).removeAttr("disabled")
            $(this).addClass("editando")
        })
        $("#botonEditar").attr("style", "display:none")
        $("#botonGuardar").removeAttr("style")
    }

    async guardar() {
        const pss = this.enlazar.enlazarInputObjetoPorClase("editable") as any
        const prestdor = JSON.parse(sessionStorage["prestador"])
        pss.IdPss = prestdor.IdPss
        //enviar al servidor

        function handler(response: any) {
            $(".editable").each(function () {
                $(this).attr("disabled", "disabled")
                $(this).removeClass("editando")
            })

            alerta(response)

            $("#botonGuardar").attr("style", "display:none")
            $("#botonEditar").removeAttr("style")
        }

        await this.repositorio.guardarPss(pss, handler)
    }

    async solicitudReestablecerContrasena(){
        let modelo = this.enlazar.enlazarInputObjeto(new reestablecerContrasenaPss()) 
        const campos =["RncoCedula","CedulaRepresentante","Correo","IdPss"]
        let camposInvalidos = this.validador.validarCampos(modelo, campos)
        
         if(camposInvalidos.length == 0){
             this.repositorio.recuperarContrasena(modelo)
         }else{
            alerta("Debe llenar los campos marcados en rojo","Advertencia",SwalType.WARNING)
         }
    }

    
    async GuardarLote() {
        let p = this.enlazar.enlazarInputObjeto(new LoteWebSite())

        let camposValidar:any = ["IdPss", "RncCedula", "NombrePss", "Correo",
                                "Telefono", "CantidadReclamos", "MontoTotalReclamos", "Lote"]
        let camposInvalidos = this.validador.validarCampos(p, camposValidar)

        //si no hay campos invalidos proceder
        if (camposInvalidos.length == 0) {
            this.repositorio.guardarLoteWebsite(p, this.manejarRespuestaLoteWebSite.bind(this))
        } else {
            alerta("Debe llenar los campos marcados en rojo","Advertencia",SwalType.WARNING)
        }
    }


    async manejarRespuestaLoteWebSite(response:any) {
            
        let promises:any = []

        promises.push(this.repositorio.guardarArchivoLote(response.IdLoteWebSite, response.IdPss))

        let responses = await Promise.all(promises)

        if (responses.some(function (v:any) { return v == false }))
        {
            HideLoader()
            alerta("Error al enviar el Lote.","Error",SwalType.ERROR)
        }
        else {
            HideLoader()
            alerta("Lote guardado, hemos enviado un acuse de recibo a su correo!","Confirmacion",SwalType.SUCCESS)
            this.enlazar.enlazarObjetoInput(new LoteWebSite())
        }
    }


    async  BuscarPssId(idPss: any) {
        const enlazar = (prestador: any) => {
            if (prestador.nombre) {
            $("#IdPss").val(prestador.IdPss);
            $("#NombrePss").val(prestador.nombre);
            $("#RncCedula").val(prestador.RncCedula);
            }
        }
        await this.repositorio.obtenerDatosPssId(idPss, enlazar)
    }

    async  BuscarPssRnc(RncCedula: any) {
        const enlazar = (prestador: any) => {
            if (prestador.nombre) {
            $("#IdPss").val(prestador.IdPss);
            $("#NombrePss").val(prestador.nombre);
            $("#RncCedula").val(prestador.RncCedula);
            }
        }
        await this.repositorio.obtenerDatosPssRnc(RncCedula, enlazar)
    }

    async enlazarConsultaRepresentanteByCedula(Cedula:string=""){
        const enlazar = (persona: any) => {
            if (persona.Cedula) {
                var d = new Date(persona.Fechnac)
            $("#NombreRepresentante").val(persona.Nombre1);
            $("#ApellidoRepresentante").val(`${persona.Apellido1} ${persona.Apellido2}`);
            $("#FechaNacimientoStrRepresentante").val(`${("0" + (d.getDate())).slice(-2)}-${("0" + (d.getMonth() + 1)).slice(-2)}-${d.getFullYear()}`);
            $('#SexoRepresentante').val(persona.Sexo);
            $('#IdEstadoCivilRepresentante').val(persona.ESTACIVIL == 'S' ? 2:1)
            }
        }
        await this.generalRepo.obtenerDatosByCedula(Cedula, enlazar)
    }
    enlazarEspecialidades(selector:string="IdEspecialidad"){
        const handler = (response:any) =>{
            this.enlazar.enlazarListaPlantilla(response, "plantillaSelectEspecialidad",selector)
        }
        this.repositorio.obtenerEspecialidades(handler);
    }
    enlazarClasificaciones(selector:string="TipoCentro"){
        const handler = (response:any) =>{
            this.enlazar.enlazarListaPlantilla(response, "plantillaSelectGeneric",selector)
        }
        this.repositorio.obtenerClasificaciones(handler);
    }
    enlazarProvinciasRepresentante(){
        const handler = (response:any) =>{
            this.enlazar.enlazarListaPlantilla(response, "plantillaSelectProvinciasRepresentante", "IdProvinciaRepresentante")
        }
        this.generalRepo.obtenerProvincias(handler);
    }
    enlazarMunicipiosRepresentante(IdProvincia:number=null){

        const handler = (response:any) =>{
            this.enlazar.enlazarListaPlantilla(response, "plantillaSelectMunicipiosRepresentante", "IdMunicipioRepresentante")
        }
        this.generalRepo.obtenerMunicipios(IdProvincia,handler)
    }
    consultarCentros() {
        let modeloConsulta = this.enlazar
            .enlazarInputObjeto(new CentroView()
            ) as CentroView
            
        const handler = (response: any) => {
            if (response && response.length >= 0) {

                enlazarCentros(response)
            }
        }
        this.repositorio.obtenerCentrosMedicos(modeloConsulta, handler)
    }
    async GuardarPssMedico() {
        let medico = this.enlazar.enlazarInputObjeto(new Medico()) as Medico;
        medico.FechaNacimiento = getFechaNacimiento(medico.FechaNacimientoStr);
        let camposValidar:any = ["Cedula","Nombres", "Apellidos", "Exequatur","Correo"]
        let especialidades = this.enlazar.enlazarInputObjetoPorClase("MedicoEspecialidad")as any;;
        medico.Especialidades = 
        {
            IdMedicoEspecialidad:null,
            IdEspecialidad:especialidades.IdEspecialidad,
            IdSolicitudMedico:null,
            Tipo:especialidades.TipoEspecialidad
        }
        medico.Centros = new Array<MedicoCentro>();
        medico.Centros.push(this.enlazar.enlazarInputObjeto(new MedicoCentro()) as MedicoCentro);
        medico.Telefonos = new Array<TelefonoMedico>();
        let tel = this.enlazar.enlazarInputObjetoPorClase("TelefonoMedico") as any;
        medico.Telefonos.push(
            {Telefono: tel.TelCasaMedico,IdSolicitudMedico:null,IdTelMedico:null,IdTipo:1},
            {Telefono: tel.TelCelularMedico,IdSolicitudMedico:null,IdTelMedico:null,IdTipo:3},
            {Telefono: tel.TelConsultorioMedico,IdSolicitudMedico:null,IdTelMedico:null,IdTipo:4});

        let camposInvalidosMedico = this.validador.validarCampos(medico, camposValidar)
        let camposInvalidosCentroMedico = this.validador.validarCamposArray(
            [medico.Centros[0].NombreCentro],["NombreCentro"]);
        let camposInvalidosTelefono = this.validador.validarCampos(tel,["TelConsultorioMedico"]);
        //si no hay campos invalidos proceder
        if (camposInvalidosMedico.length == 0 && camposInvalidosCentroMedico.length==0 && camposInvalidosTelefono.length==0) {
            const resp = await confirmar("Está seguro de enviar esta solicitud?","Confirmacion de Envio",SwalType.INFO);
            if(resp)
                this.repositorio.GuardarPssMedico(medico,this.DocumentosPss.bind(this));
        } else {
            alerta("Debe llenar los campos marcados en rojo","Advertencia",SwalType.WARNING)
        }
    }
  
    async GuardarPssCentro() {
        let centro = this.enlazar.enlazarInputObjeto(new Centro()) as Centro;
        // medico.FechaNacimiento = getFechaNacimiento(medico.FechaNacimientoStr);
        let camposCentroValidar:any = ["RNCEMPR","RAZONSC","IdGrupo","IdAreaSalud","IdProvincia","IdMunicipio"]
        let camposRepresentanteValidar:any=
        ["CedulaRepresentante","NombreRepresentante","ApellidoRepresentante","CorreoCentro"]
        let tel = this.enlazar.enlazarInputObjetoPorClase("TelefonoCentro") as any;
        centro.Telefonos = new Array<TelefonoCentro>();
        centro.Telefonos.push(
            {Telefono: tel.TelCentro1,IdSolicitudCentro:null,IdTipo:2,IdTelCentro:null},
            {Telefono: tel.TelCentro2,IdSolicitudCentro:null,IdTipo:2,IdTelCentro:null},
            {Telefono: tel.TelCentro3,IdSolicitudCentro:null,IdTipo:2,IdTelCentro:null},
            {Telefono: tel.TelCentro4,IdSolicitudCentro:null,IdTipo:2,IdTelCentro:null});
        let representante = this.enlazar.enlazarInputObjetoPorClase("RepresentanteCentro") as any;
        centro.Representante=
        {
            IdRepresentante:null,
            Cedula:representante.CedulaRepresentante,
            Nombres:representante.NombreRepresentante,
            Apellidos:representante.ApellidoRepresentante,
            FechaNacimientoStr: representante.FechaNacimientoStrRepresentante,
            FechaNacimiento:getFechaNacimiento(representante.FechaNacimientoStrRepresentante),
            Sexo:representante.SexoRepresentante,
            IdEstadoCivil: representante.IdEstadoCivilRepresentante,
            IdNacionalidad: representante.IdNacionalidadRepresentante,
            IdTitulo:representante.IdTituloRepresentante,
            Ocupacion:representante.OcupacionRepresentante,
            IdCiudad: representante.IdProvinciaRepresentante,
            IdSector: representante.IdMunicipioRepresentante,
            Direccion:representante.DireccionRepresentante,
            Nombre1:null,
            Apellido1:null,
            Apellido2:null,
        }
        centro.Correo = (this.enlazar.enlazarInputObjetoPorClase("CentroDatos") as any).CorreoCentro;
        let camposInvalidosCentro = this.validador.validarCampos(centro, camposCentroValidar)
        let camposInvalidosRepr = this.validador.validarCamposArray
        (
            [centro.Representante.Cedula,centro.Representante.Nombres,centro.Representante.Apellidos,centro.Correo],camposRepresentanteValidar
        );
        let camposInvalidosTel = this.validador.validarCampos(tel,["TelCentro1"]);
        //si no hay campos invalidos proceder
        if (camposInvalidosCentro.length == 0 && camposInvalidosRepr.length == 0 && camposInvalidosTel.length==0) {
            const resp = await confirmar("Está seguro de enviar esta solicitud?","Confirmacion de Envio",SwalType.INFO);
            if(resp)
                this.repositorio.GuardarPssCentro(centro,this.DocumentosPss.bind(this));
        } else {
            alerta("Debe llenar los campos marcados en rojo","Advertencia",SwalType.WARNING)
        }
    }
    async DocumentosPss(response:any){
        let promises:any = []
        let tipo = response.hasOwnProperty("IdSolicitudMedico") ;
        switch(tipo){
            case true:
                sessionStorage.setItem("Grupo",response.IdClasificacion);
                sessionStorage.setItem("IdSolicitud",response.IdSolicitudMedico)
                sessionStorage.setItem("TipoSol",`${EnumTipoSolicitudPSS.MEDICO}`)
                break;
            case false:
                sessionStorage.setItem("Grupo",response.IdGrupo);
                sessionStorage.setItem("IdSolicitud",response.IdSolicitudCentro)
                sessionStorage.setItem("TipoSol",`${EnumTipoSolicitudPSS.CENTRO}`)
                break;
        }
        sessionStorage.setItem("IdClasificacion",response.IdClasificacion);
        promises.push(window.location.href= `./DocumentosPSS.html`)

        let responses = await Promise.all(promises)
    } 
    async CargarDocumentos(){
        var response = {
            "TipoSol":sessionStorage.getItem("TipoSol"),
            "IdSolicitud":parseInt(sessionStorage.getItem("IdSolicitud"))
        };
        var IdClasificacion = parseInt(sessionStorage.getItem("IdClasificacion"))
        var IdGrupo = parseInt(sessionStorage.getItem("Grupo"))
        let doc:any;
        switch(IdClasificacion){
            case 1:
                if(IdGrupo==6)
                    doc = this.enlazar.enlazarInputFilePorClase("Doc-Centro-Odontologico")
                else
                    doc = this.enlazar.enlazarInputFilePorClase("Doc-Centro-Medico")
                break;
            case 2:
                break;
            case 3:

                doc = this.enlazar.enlazarInputFilePorClase("Doc-Centro-Diagnostico")
                break;
            case 4:
                doc = this.enlazar.enlazarInputFilePorClase("Doc-Farmacia")
                break;
            case 5:
                doc = this.enlazar.enlazarInputFilePorClase("Doc-Medico")
                break;
        }
        if(response.hasOwnProperty("IdSolicitud") && response.hasOwnProperty("TipoSol")){
            const resp = await confirmar("Seguro de cargar estos archivos?","Confirmar Carga",SwalType.INFO)
            if(resp)
                this.manejarRespuestaDocumentosPSS(response,doc);
        }
    }


    async manejarRespuestaDocumentosPSS(response:any,doc:any) {
        let promises:any = []

        promises.push(this.repositorio.GuardarArchivoSolicitudPSS(response,doc))

        let responses = await Promise.all(promises)

        if (responses.some(function (v:any) { return v == false }))
        {
            // HideLoader()
            alerta("Error al enviar Documentos.","Error",SwalType.ERROR);
        }
        else {
            // HideLoader()
            const emailResp = await this.repositorio.EnviarMailRecepcionSolicitudPSS(response);
            const storage = await sessionStorage.clear();
            const resp = await alerta("Documentos guardados, hemos enviado la solicitud a su correo!","Carga Exitosa",SwalType.SUCCESS)
      
            location.href = './index.html';
            // this.enlazar.enlazarObjetoInput(new LoteWebSite())
        }
    }
    seleccionarCentroBusqueda(id:number=null,value:string=""){
        $('#IdCentroMedico').val(id)
        $('#NombreCentro').val(value)
    }
    async seleccionarTipoDocumento(){
        if(sessionStorage.length>1){
            var Clasificacion = parseInt(sessionStorage.getItem("IdClasificacion"));
            var IdGrupo = parseInt(sessionStorage.getItem("Grupo"));
            switch(Clasificacion){
                case 1:
                    if(IdGrupo == 6)
                        $('#tab_Doc_CentroOdontologico').css("display","inline");
                    else
                        $('#tab_Doc_CentroMedico').css("display","inline");
                    break;
                case 2:
                    break;
                case 3:
                    $('#tab_Doc_CentroDiagnosticoLaboratorio').css("display","inline");
                    break;
                case 4:
                    $('#tab_Doc_Farmacia').css("display","inline");
                    break;
                case 5:
                    $('#tab_Doc_Medico').css("display","inline");
                    break;
            }    
        }
        else
        {
            const resp = await alerta("Debe realizar la solicitud de PSS primero","Aviso",SwalType.INFO);
            location.href="./Prestadores.html";
        }
     

    }

}
function enlazarPrestadores(prestadores: any) {
    var table = $('#TablaAutorizaciones').DataTable()
    table.clear()
    table.rows.add(prestadores)
    table.draw()

}

function enlazarCentros(centros: Array<any>) {
    var table = $('#TblCentros').DataTable()
    table.clear()
    for(let centro of centros)
    {
        table.row.add([centro.Nombre,centro.Grupo,centro.Provincia,centro.Municipio,centro.Direccion,
            `<i onclick="controlador.seleccionarCentroBusqueda(${centro.IdCentro},'${centro.Nombre}');" data-dismiss='modal'  class="fa fa-eye"  style="cursor: pointer;color:black;font-size: 24px;" title="Seleccionar" ></i>`])
    }
    table.draw()
}




