
import HttpClient from '../servicios/HttpClient'
import { baseURl, alerta,SwalType } from '../utilidades/SinClasificar'
import { reestablecerContrasenaAfiliado } from '../modelos/reestablecerContrasena'
import {EnumEstadoFactura}  from'../modelos/EnumClasses'
var error = (response: any) => {
    alerta("Se ha producido un error inesperado",null,SwalType.ERROR)
}

declare var JSON: any
declare var swal: any
declare var HideLoader: any

class afiliadoRepositorio {
    httpClient: HttpClient
    constructor() {
        this.httpClient = new HttpClient()
    }

    async  obtenerDatosPlanes(IdAfiliado, handler) {
        const success = function (response) {
            if (response) {
                handler(response)
            }
        }

        let url = baseURl + "api/afiliados/ObtenerPlanesActivosAfiliado/";

        await this.httpClient.getJson(url + IdAfiliado, success, error)
    }

    async obtenerNucleoFamiliar(cedula, handler) {
        const success = function (response) {
            if (response) {
                handler(response)
            }
        }
        let url = baseURl + "api/nucleofamiliar/";

        await this.httpClient.getJson(url + cedula, success, error)
    }

    async obtenerDatosAfiliado(IdAfiliado, handler) {
        const success = function (response) {
            if (response) {
                handler(response)
            }
        }

        let url = baseURl + 'api/afiliados/DatosGeneralesPorId/' + IdAfiliado
        await this.httpClient.getJson(url, success, error)
    }
    async obtenerDatosAfiliadoPorPromotor(IdPromotor:number, handler:any) {
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }

        let url = baseURl + 'api/afiliados/DatosAfiliadosPorPromotor/' + IdPromotor
        await this.httpClient.getJson(url, success, error)
    }

    async obtenerDatosHistorial(id) {
        let historial = []

        const success = function (response) {
            if (response) {
                historial = response //.filter(function(x){ return x.Parentesco != "TITULAR"})
            }
        }
        let url = baseURl + "api/ObtenerHistorialDeConsumoAfiliado/";

        await this.httpClient.getJson(url + id, success, error)

        return historial;
    }

    async obtenerDatosConsumo(id, handler) {
        const success = function (response) {
            if (response) {
                handler(response)
            }
        }

        let url = baseURl + "api/afiliados/ObtenerConsolidadoConsumoAfiliado/";

        await this.httpClient.getJson(url + id, success, error)
    }

    async obtenerDatosConsumoAltoCosto(id, handler) {
        const success = function (response) {
            if (response) {
                handler(response)
            }
        }

        let url = baseURl + "api/afiliados/ObtenerConsumoAltoCosto/";

        await this.httpClient.getJson(url + id, success, error)
    }

    async obtenerDatosHistorialAutorizaciones(id, handler) {
        const success = function (response) {
            if (response) {
                handler(response)
            }
        }

        let url = baseURl + "api/afiliados/ObtenerHistorialDeConsumoAfiliado/";

        await this.httpClient.getJson(url + id, success, error)
    }

    async guardarAfiliado(afiliado, handler) {
        const success = function (response) {
            if (response) {
                if (handler) handler(response)
            }
        }

        let url = baseURl + "api/afiliados/CambiarDatosContactos";

        await this.httpClient.postJson(url, afiliado, success, error)
    }

    async validaAfiliado(a: any) {
        let url = baseURl
            + `api/afiliados/${a.Cedula}/${a.Nss}/${a.Correo}/${a.IdAfiliado}`;
        let valid = false;

        const success = function (response: any) {
            valid = true
        }
        const error = function (response: any) {
            alerta(JSON.parse(response).Message,null,SwalType.ERROR)
        }

        await this.httpClient.getJson(url, success, error)

        return valid;
    }

    async registrar(afiliado: any) {
        let url = baseURl
            + "api/afiliados";
        const success = function (response: any) {
            window.location.href = "registrado.html"
        }
        const error = function (response: any) {
            alerta(JSON.parse(response).Message,null,SwalType.ERROR)
        }

        this.httpClient.postJson(url, afiliado, success, error);
    }

    iniciarSesion(model: any, handler: any) {
        const action = baseURl + "api/afiliados/iniciosesion"
        const error = function (response: any) {
            HideLoader();
            alerta(JSON.parse(response).Message,null,SwalType.ERROR)
        }

        this.httpClient.postJson(action, model, handler, error)
    }

    async  obtenerDatosReembolso(cedula: string, handler: any) {
        const success = function (response: any) {
            if (response) {
                handler(response)
            }
        }

        let url = baseURl + `api/Autorizaciones/ObtenerHistorialReembolsos/${cedula}`;

        await this.httpClient.getJson(url, success, error)
    }

    async guardarReembolso(reembolso: any, handler: any) {
        const success = function (response: any) {
            if (response) {
                if (handler) handler(response)
            }
        }

        let url = baseURl + "api/Autorizaciones/SolicitudReembolso";

        await this.httpClient.postJson(url, reembolso, success, error)
    }

    async guardarImagenReembolso(id: any, idAfiliado: any, tipo: any) {
        let result = false
        const success = function (response: any) {
            result = true
        }
        let file = (document.getElementById(tipo) as any).files[0]

        let url = baseURl + `api/imagenes/reembolso/${id}/${idAfiliado}/${tipo}`;

        const e = function () {
            console.log(e)
        }

        await this.httpClient.postFile(url, file, success, e)

        return result
    }


    async obtenerDatosPreCertificadas(cedula: any, handler: any) {
        const success = function (response: any) {
            if (response && typeof response == "object") {
                response = response.map((x: any) => {
                    x.pdf = baseURl + `api/Autorizaciones/ObtenerPdfAutorizacion/${x.NumeroAutorizacion}`
                    return x
                })
                handler(response)
            }
        }

        let url = baseURl + `api/Autorizaciones/ObtenerHistorialPrecertificadas/${cedula}`;

        await this.httpClient.getJson(url, success, error)
    }

    async guardarPreCertificada(p: any, handler: any) {
        const success = function (response: any) {
            if (response) {
                if (handler) handler(response)
            }
        }

        let url = baseURl + "api/Autorizaciones/SolicitudPrecertificadas";

        await this.httpClient.postJson(url, p, success, error)
    }

    async  guardarImagenPreCertificada(id: any, idAfiliado: any, tipo: any) {
        let result = false
        const success = function (response: any) {
            result = true
        }
        let file = (document.getElementById(tipo) as any).files[0]

        let url = baseURl + `api/imagenes/precertificadas/${id}/${idAfiliado}/${tipo}`;

        const e = function () {
            console.log(e)
        }

        await this.httpClient.postFile(url, file, success, e)

        return result
    }

    async obtenerFacturasPagadas(cedula: any, handler: any) {
        const success = function (response: any) {
            if (response && typeof response == "object") {
                response = response.map((x: any) => {
                    x.recibo = baseURl + `api/Afiliados/ObtenerReciboIngreso/${x.IdFactura}`
                    return x
                })
                handler(response)
            }
            if(response && typeof response == 'string'){
                handler([])
                alerta(`${response}`)
            }
        }
        let url = baseURl + `api/Afiliados/ObtenerHistorialPagos/${cedula}`;
        await this.httpClient.getJson(url, success, error)
    }
    async obtenerFacturasPorPromotor(idPromotor:number,estadoFactura:number, handler: any) {
        const success = function (response: any) {
         
            if (response && typeof response =="object") {
                response = response.map((x: any) => {
                    if(estadoFactura == EnumEstadoFactura.PENDIENTE_PAGO)
                        x.factura = `factura${(x.IdTipoCliente==2) ? "Promotor":""}.html?idfactura=${x.IdFactura}`
                    else if(estadoFactura == EnumEstadoFactura.PAGADA)
                        x.recibo = baseURl + `api/Afiliados/ObtenerReciboIngreso/${x.IdFactura}`
                    return x
                })
                handler(response)
            }
            if(response && typeof response == 'string'){
                handler([])
                alerta(`${response}`)
            }
               
        }
        let url = baseURl + `api/Afiliados/ObtenerHistorialPagosPorPromotor/${idPromotor}/${estadoFactura}`;
        await this.httpClient.getJson(url, success, error)
    }


    async obtenerFacturas(cedula: any, handler: any) {
        const success = function (response: any) {
            if (response && typeof response == "object") {
                response = response.map((x: any) => {

                    x.factura = `factura.html?idfactura=${x.IdFactura}`
                    return x
                })
                handler(response)
            }
            if(response && typeof response == 'string'){
                handler([])
                alerta(`${response}`)
            }
        }
        let url = baseURl + `api/Afiliados/ObtenerFacturasPendientesPago/${cedula}`;
        await this.httpClient.getJson(url, success, error)
    }


    async  obtenerFacturaCabecera(IdFactura: number, handler) {
        const success = function (response) {
            if (response) {
                handler(response)
            }
        }

        let url = baseURl + "api/afiliados/Obtenercabecerafactura/";

        await this.httpClient.getJson(url + IdFactura, success, error)
    }

    async obtenerFacturaDetalle(IdFactura: number, handler: any) {
        const success = function (response: any) {
            if (response) {
                handler(response)
            }
        }

        let url = baseURl + `api/Afiliados/ObtenerDetallefactura/${IdFactura}`;

        await this.httpClient.getJson(url, success, error)
    }



    async GuardarTransaccionAzul(transaccionAzul: any) {
        const success = function (response: any) {
        }
        const error = function (response: any) {
            alerta(JSON.parse(response).Message,null,SwalType.ERROR)
        }
        let url = baseURl + "api/afiliados/GuardarTransaccionAzul";
        this.httpClient.postJson(url, transaccionAzul, success, error);
    }

    async recuperarContrasena(modelo: any) {
        const success = function (response: any) {
            alerta("Solicitud de recuperacion de contraseña enviada",null,SwalType.SUCCESS)
                .then(() => {
                    window.location.href = "/loginAfiliados.html"
                })

        }

        let url = baseURl + `api/afiliados/Recuperar/${modelo.cedula.trim()}/${modelo.nss.trim()}/${modelo.afiliadoId.trim()}`;
        this.httpClient.getJson(url, success, error);
    }

}

export default afiliadoRepositorio