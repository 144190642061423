
import { alerta,confirmar,SwalType } from '../utilidades/SinClasificar'

declare var RegExp: any
declare var Array:any
declare var Object:any
declare var $:any

export default class Validador {

    constructor() { }

    validaContrasena(contrasena: any, contrasenaConf: any) {
        var regexNumero = new RegExp("^(?=.*[0-9])");
        var regexMayuscula = new RegExp("^(?=.*[a-z])(?=.*[A-Z])");
        if (contrasena.length < 8) {
            alerta("La contraseña debe tener al menos 8 caracteres",null,SwalType.INFO)
            return false;
        }
        else if (!regexNumero.test(contrasena)) {
            alerta("La contraseña debe tener al menos un caracter numerico",null,SwalType.INFO)
            return false;
        }
        else if (!regexMayuscula.test(contrasena)) {
            alerta("La contraseña debe incluir letras en minuscula y mayuscula",null,SwalType.INFO)
            return false;
        }
        else if (contrasena !== contrasenaConf) {
            alerta("La confirmacion de la contraseña no coincide",null,SwalType.INFO)
            return false;
        }



        return true;
    }
    validarCamposArray(obj:any, campos:any,className:string="campo-invalido"):any {
        if (!(campos instanceof (Array))) {
            campos = Object.keys(campos)
        }

        let unValid:any = []
        campos.forEach(function (c:any,k:number) {
            if (obj[k] == "" || obj[k] == undefined || obj[k] == null) {
                unValid.push(c)
                
                $(`#${c}`).addClass(className)
            } else {
                $(`#${c}`).removeClass(className)
            }
        })
        return unValid
    }

    validarCampos(obj:any, campos:any,selector:string=null,className:string="campo-invalido"):any {
        if (!(campos instanceof (Array))) {
            campos = Object.keys(campos)
        }
        let unValid:any = []
        campos.forEach(function (c:any) {
            if (obj[c] == "" || obj[c] == undefined || obj[c] == null) {
                unValid.push(c)

                $(`#${c} ${(typeof selector != null) ? selector : ""} `).addClass(className)
            } else {
                $(`#${c} ${(typeof selector != null) ? selector : ""} `).removeClass(className)
            }
        })

        return unValid
    }

    validarCamposSiblings(obj:any, campos:any,selector:string=null,className:string="campo-invalido"):any {
        if (!(campos instanceof (Array))) {
            campos = Object.keys(campos)
        }
        let unValid:any = []
        campos.forEach(function (c:any) {
            if (obj[c] == "" || obj[c] == undefined || obj[c] == null) {
                unValid.push(c)

                $(`#${c}`).siblings(`.${(typeof selector != null) ? selector : ""}`).addClass(className)
            } else {
                $(`#${c}`).siblings(`.${(typeof selector != null) ? selector : ""}`).removeClass(className)
            }
        })

        return unValid
    }

}