class Reembolso {
    IdAfiliado= ""
    Cedula= ""
    NombrePss= "" 
    RazonSolicitud= ""
    FechaSolicitud= ""
    FechaSolicitudStr= ""
    FechaServicio= ""
    FechaServicioStr= "" 
    MontoPagado= 0
    FormaPago=  ""
    NumeroCuenta= ""
    TipoCuenta= ""
    IdBanco= 0
    Banco= ""
    IdSolicitudReembolso= 0
    NombreAfiliado= ""
    DiasCompromiso= ""
    HorasCompromiso= ""
    MinutosCompromiso= ""
    EstatusSolicitud= ""
    RP= ""
    CAR= ""
    CC= ""
}

export default Reembolso