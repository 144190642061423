
import PromotorView from '../modelos/PromotorView'
import HttpClient from '../servicios/HttpClient'
import { baseURl, alerta, SwalType } from '../utilidades/SinClasificar'

var error = (response: any) => {
    console.log(response)
    alerta("Se ha producido un error inesperado",null,SwalType.ERROR)
}

declare var JSON: any
declare var swal: any
declare var HideLoader: any

class promotorRepositorio {
    httpClient: HttpClient
    constructor() {
        this.httpClient = new HttpClient()
    }

   

    async obtenerDatosPromotor(IdPromotor:number, handler:any) {
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }
        let url = baseURl + 'api/promotores/DatosGeneralesPorId/' + IdPromotor
        await this.httpClient.getJson(url, success, error)
    }


    iniciarSesion(model: any, handler: any) {
        const action = baseURl + "api/promotores/InicioSesion"
        const error = function (response: any) {
            HideLoader();
            if(JSON.parse(response).Message == "Nuevo"){
                handler(JSON.parse(response).Message)
            }else{
                alerta(JSON.parse(response).Message,null,SwalType.ERROR)
            }
        }

        this.httpClient.postJson(action, model, handler, error)
    }

    

    async recuperarContrasena(modelo: any) {
        const success = function (response: any) {
            alerta("Solicitud de recuperacion de contraseña enviada",null,SwalType.SUCCESS)
                .then(() => {
                    window.location.href = "/loginPromotores.html"
                })
        }
        let url = baseURl + `api/promotores/Recuperar/${modelo.Identificacion.trim()}/${modelo.IdPromotor.trim()}`;
        this.httpClient.getJson(url, success, error);
    }
    async cambiarCredenciales(model: any,handler:any) {
        const action = baseURl + "api/promotores/CambiarCredenciales"
        const error = function (response: any) {
            // HideLoader();
            alerta(JSON.parse(response).Message,null,SwalType.ERROR)
        }
        await this.httpClient.postJson(action,model, handler, error)
    }
    async obtenerPagosPromotor(IdPromotor:number, handler:any) {
        const success = function (response:any) {
            if (response) {
                if(typeof response == "object")
                    handler(response)
                else
                    handler([])
            }
            else
                handler([])
            
        }
        let url = baseURl + 'api/promotores/PagosRecibidosPorId/' + IdPromotor
        await this.httpClient.getJson(url, success, error)
    }

}

export default promotorRepositorio