class LoteWebSite{
    IdPss= 0
    Correo= "" 
    Telefono= "" 
    CantidadReclamos= 0
    MontoTotalReclamos= 0
    Lote= "" 
    RncCedula=""
    NombrePss=""
}

export default LoteWebSite