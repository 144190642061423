
export default class registroPrestador{
    constructor(){

    }
    
        IdPss:number = 0
        nombre:string = ""
        RncCedula: string = ""
        CedulaRepresentante:string = ""
        Correo:string = ""
        Contrasena:string = ""
        OrigenCreacion:string = ""
      
}