import TelefonoCentro from '../modelos/TelefonoCentro';
import Representante from '../modelos/Representante';
import Empresa from './Empresa';
export default class Centro extends Empresa {
    IdSolicitudCentro:number=null;
    IdTipoContrato:number=null;
    Contacto:string="";
    IdGrupo:number=null;
    Grupo:string=""
    CodSespas:number=null;
    Habilitacion:string="";
    IdAreaSalud:number=null;
    IdProvincia:number=null;
    Provincia:string="";
    IdMunicipio:number=null;
    Municipio:string="";
    Direccion:string=""
    Estado:string=null;
    IdRepresentante:number=null;
    IdClasificacion:number=null;
    Correo:string="";
    Representante:Representante = new Representante();
    Telefonos:Array<TelefonoCentro> = new Array<TelefonoCentro>()
}