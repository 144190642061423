import PromotorRepositorio from '../repositorios/PromotorRepositorio'
import Validador from '../utilidades/Validador'
import Enlazar from '../utilidades/Enlazar'
import { formatNumber, alerta,formatToPercentage, clearStorageCookies,getCookie,confirmar,SwalType } from '../utilidades/SinClasificar'
import {EnumTipoIdentificacion,EnumTipoPromotor,EnumCategoriaPromotor} from'../modelos/EnumClasses'
import PromotorView from '../modelos/PromotorView'
import { CambioCredenciales } from '../modelos/reestablecerContrasena'
import PagoPromotor from '../modelos/PagoPromotor'
import {reestablecerContrasenaPromotor} from '../modelos/reestablecerContrasena'

declare var $: any
declare var JSON: any
declare var ShowLoader: any
declare var HideLoader: any
declare var Promise:any 

export default class AfiliadoControlador {
    promotorRepositorio: PromotorRepositorio
    validador: Validador
    enlazar: Enlazar
    constructor() {
        this.promotorRepositorio = new PromotorRepositorio()
        this.validador = new Validador()
        this.enlazar = new Enlazar()
    }

  

    async iniciaSesion() {
        ShowLoader();
        let identificacion = $("#identificacion").val() || 0;
        let contrasena = $("#contrasena").val() || 0;
      
        const handler = (response: any): any => {
            //crear cookie y sesion
            if (!response) return null;
            if(response.status == "Nuevo"){
                sessionStorage.setItem('tmppromotor',JSON.stringify(response.model) )
                return  window.location.href = "./CambioCredencialPromotores.html"
            } 
            let promotor = response.model;

            if (sessionStorage.getItem('promotor'))
                sessionStorage.clear();

            sessionStorage.setItem('promotor', JSON.stringify(promotor))
            sessionStorage.setItem('token', "")
            //set cookie
            document.cookie = "token=;"
            document.cookie = "identificacion=" + promotor.Identificacion + ";"
            window.location.href = "indexPromotores.html"
        }
        if(identificacion == 0 || contrasena == 0){
            HideLoader()   
        }else{
            let request = { Identificacion: identificacion, Contrasena: contrasena }
            this.promotorRepositorio.iniciarSesion(request, handler)
        }

    }

    async revisarSesion() {
        let promotor = JSON.parse(sessionStorage.getItem('promotor'))
        if (!promotor) window.location.href = "loginPromotores.html"
        this.enlazar.enlazarObjetoValor("NombrePromotorEtiqueta", promotor,".");
    
    }
    async cerrarSesion(){
        clearStorageCookies()
    }

    async mostrarDatosPromotor(promotorId:number){
        let camposPorcentaje:any = ["EmisionVoluntario","RenovacionVoluntario","EmisionBasico","RenovacionBasico"]
        let promotor:PromotorView = formatToPercentage(JSON.parse(sessionStorage.getItem('promotor')),camposPorcentaje) as PromotorView
        // let promotor:PromotorView = JSON.parse(sessionStorage.getItem('promotor'),camposPorcentaje) as PromotorView
        this.enlazar.enlazarObjetoInput(promotor) 
        $("input").each(function () {
            $(this).attr("disabled", "disabled")
        })
    }
    

    
    async editar() {
        $(".editable").each(function () {
            $(this).removeAttr("disabled")
            $(this).addClass("editando")
        })
        $("#botonEditar").attr("style", "display:none")
        $("#botonGuardar").removeAttr("style")
    }

    async solicitudCambioCredenciales() {
        const handler = function (response: any) {
            ShowLoader();
            let isTemp = (sessionStorage.hasOwnProperty("tmppromotor"))
            if(isTemp)
                sessionStorage.clear();
            alerta("Cambio de contraseña exitoso",null,SwalType.SUCCESS)
            .then(() => {
                window.location.href = 
                (isTemp) ? "/loginPromotores.html" : "/indexPromotores.html"
            })
        }
        let identificacion:string
        if(sessionStorage.hasOwnProperty("tmppromotor"))
            identificacion = JSON.parse(sessionStorage.getItem('tmppromotor')).Identificacion;
        else if(sessionStorage.hasOwnProperty("promotor"))
            identificacion = JSON.parse(sessionStorage.getItem('promotor')).Identificacion;
        else
            window.location.href="./loginPromotores.html"
        let credenciales = this.enlazar.enlazarInputObjeto(new CambioCredenciales()) as CambioCredenciales;
        credenciales.Identificacion = identificacion
        let camposValidar:any = ["Identificacion","ContrasenaAnterior","ContrasenaNueva", "ConfirmarContrasena"]
        let camposInvalidos = this.validador.validarCamposSiblings(credenciales, camposValidar,"focus-input100","invalid-input")
        if (camposInvalidos.length == 0) {
            if(this.validador.validaContrasena(credenciales.ContrasenaNueva,credenciales.ConfirmarContrasena)){
                const resp = await confirmar("Está seguro de cambiar las credenciales?",null,SwalType.INFO);
                if(resp)
                    this.promotorRepositorio.cambiarCredenciales(credenciales,handler)
            }
        } else {
            alerta("Debe llenar los campos marcados en rojo","Advertencia",SwalType.WARNING)
        }

     
    
    }
    async solicitudReestablecerContrasena(){
        let modelo = this.enlazar.enlazarInputObjeto(new reestablecerContrasenaPromotor()) 
        let camposValidar:any = ["Identificacion","IdPromotor","Correo"]
        let camposInvalidos = this.validador.validarCamposSiblings(modelo, camposValidar,"focus-input100","invalid-input")
        if(camposInvalidos.length == 0){
             this.promotorRepositorio.recuperarContrasena(modelo)
         }else{
            alerta("Debe llenar los campos marcados en rojo")
         }
    }

    async enlazarHistorialPagosPorPromotor(selector:string) {
        let promotorId: number = JSON.parse(sessionStorage.getItem('promotor')).Id
        const enlazar = (pagos: PagoPromotor) => {
            $(`#${selector} > .dataTables_length`).empty()
            $(`#${selector} >.dataTables_filter`).empty()
            $(`#${selector} >.dataTables_info`).empty()
            $(`#${selector} >.dataTables_paginate`).empty()
            if ( $.fn.dataTable.isDataTable(  $(`#${selector}`) )) {
                $(`#${selector}`).DataTable().clear().destroy();
            }
            $(`#${selector}`).DataTable({
                language: {
                    sProcessing: "Procesando...",
                    sLengthMenu: "Mostrar _MENU_ registros",
                    sZeroRecords: "No se encontraron resultados",
                    sEmptyTable: "Ningún dato disponible en esta tabla",
                    sInfo: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    sInfoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
                    sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
                    sInfoPostFix: "",
                    sSearch: "Buscar:",
                    sUrl: "",
                    sInfoThousands: ",",
                    sLoadingRecords: "Cargando...",
                    oPaginate: {
                        sFirst: "Primero",
                        sLast: "Último",
                        sNext: "Siguiente",
                        sPrevious: "Anterior"
                    },
                    oAria: {
                        sSortAscending: ": Activar para ordenar la columna de manera ascendente",
                        sSortDescending: ": Activar para ordenar la columna de manera descendente"
                    }
                },
                data: pagos,
                columns: [
                    { data: 'FechaStr' },
                    { data: 'APagarStr' },
                    { data: 'Concepto' },
                    { data: 'TipoPago' }
                ],
                pageLength:10,
                order: [],
                lengthMenu: [5, 10, 25, 50]
            });
            HideLoader()
        }
        await this.promotorRepositorio.obtenerPagosPromotor(promotorId, enlazar)
    }

}