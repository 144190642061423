class TransaccionAzul {
    IdTransaccion= 0
    OrderNumber= 0
    Amount= 0 
    Itbis= 0 
    AuthorizationCode= ""
    DateTime = ""
    ResponseCode= ""
    IsoCode= ""
    RRN= ""
    DataVaultToken= ""
    DataVaultExpiration= ""
    DataVaultBrand= ""
    AzulOrderId= ""
    ResponseMessage= ""
}

export default TransaccionAzul
