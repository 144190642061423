
export default class Persona  {

    Cedula:string="";
    Nombre1:string="";
    Apellido1:string="";
    Apellido2:string="";
    IdNacionalidad:number=null;
    Sexo:string="";
    IdEstadoCivil:number=null;
    FechaNacimientoStr:string="";
    FechaNacimiento:Date=new Date();
  
    Nombres:string = this.Nombre1;
    Apellidos:string = `${this.Apellido1} ${this.Apellido2}`


}