export enum EnumTipoSolicitudPSS
{
    MEDICO=1,
    CENTRO=2
}

export enum EnumTipoIdentificacion
{
    CEDULA=1,
    RNC=2
}

export enum EnumTipoPromotor
{
    CORREDOR=1,
    GESTOR=2
}

export enum EnumCategoriaPromotor
{
    INDIVIDUAL=1,
    CORPORATIVO = 2
}

export enum EnumEstadoFactura
{
    PENDIENTE_PAGO = 1,
    PAGADA = 2,
    NULA = 3,
    NOTA_DE_CREDITO = 4
}

