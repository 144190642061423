import Validador from '../utilidades/Validador'
import Enlazar from '../utilidades/Enlazar'
import Contacto from '../modelos/contacto'
import ContactoRepositorio from '../repositorios/ContactoRepositorio'
import {alerta} from '../utilidades/SinClasificar'

export default class ContactoController{
constructor(){
    this.enlazar = new Enlazar()
    this.validador = new Validador()
    this.repositorio = new ContactoRepositorio()
}
enlazar:Enlazar
validador:Validador
repositorio:ContactoRepositorio

    validarYGuardarFormularioContacto = () => {
        const handler = (response:any) => {
            alerta("su mensaje se ha enviado correctamente")
            this.enlazar.enlazarObjetoInput(new Contacto())
        }
        let p = this.enlazar.enlazarInputObjeto(new Contacto()) as Contacto
        let camposValidar:any = ["NombreContacto", "Correo", "Telefono", "Asunto","Cedula","Mensaje"]
        let camposInvalidos = this.validador.validarCampos(p, camposValidar)
    
        //si no hay campos invalidos proceder
        if (camposInvalidos.length == 0) {
            p.Nombre = p.NombreContacto
            this.repositorio.guardarDatosContacto(handler, p)
        } else {
            alerta("debe llenar los campos marcados en rojo")
        }
    }
    
}