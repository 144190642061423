class reestablecerContrasenaAfiliado{
    cedula:string= ""
    correo:string= ""
    afiliadoId:string= ""
    nss:string= ""
}

class reestablecerContrasenaPss{
    RncoCedula:string= ""
    CedulaRepresentante:string= ""
    Correo:string= ""
    IdPss:string= ""
}

class reestablecerContrasenaPromotor{
    Identificacion:string= ""
    Correo:string= ""
    IdPromotor:string= ""
}

class CambioCredenciales{
    Identificacion:string=null
    ContrasenaAnterior:string=null
    ContrasenaNueva:string=null
    ConfirmarContrasena:string=null
}

export { reestablecerContrasenaAfiliado, reestablecerContrasenaPss,reestablecerContrasenaPromotor,CambioCredenciales}