import AfiliadoRepositorio from '../repositorios/AfiliadoRepositorio'
import Validador from '../utilidades/Validador'
import Enlazar from '../utilidades/Enlazar'
import { formatNumber, alerta,clearStorageCookies } from '../utilidades/SinClasificar'
import Reembolso from '../modelos/reemboso'
import PreCertificada from '../modelos/preCertificada'
import TransaccionAzul from '../modelos/transaccionAzul' 
import { reestablecerContrasenaAfiliado } from '../modelos/reestablecerContrasena'
import Factura from '../modelos/Factura'
import {EnumEstadoFactura}  from'../modelos/EnumClasses'

declare var $: any
declare var JSON: any
declare var ShowLoader: any
declare var HideLoader: any
declare var Promise:any

export default class AfiliadoControlador {
    afiliadoRepositorio: AfiliadoRepositorio
    validador: Validador
    enlazar: Enlazar
    constructor() {
        this.afiliadoRepositorio = new AfiliadoRepositorio()
        this.validador = new Validador()
        this.enlazar = new Enlazar()
    }

    async validarRegistrar() {
        var afiliado: any = { Id: 0, Validado: true, OrigenCreacion: "WebSite", FechaCreacion: "" }

        afiliado.Cedula = $("#cedula").val() || 0;
        afiliado.Nss = $("#nss").val() || 0;
        afiliado.IdAfiliado = $("#idAfiliado").val() || 0;
        afiliado.Correo = $("#correo").val() || 0;
        afiliado.Contrasena = $("#Contrasena").val() || "";
        let contrasenaConf = $("#confContrasena").val() || "";
        if (!this.validador.validaContrasena(afiliado.Contrasena, contrasenaConf))
            return false;

        afiliado.Validado = await this.afiliadoRepositorio.validaAfiliado(afiliado);


        if (afiliado.Validado)
            this.afiliadoRepositorio.registrar(afiliado)

    }

    async iniciaSesion() {
        ShowLoader();
        let cedula = $("#cedula").val() || 0;
        let contrasena = $("#contrasena").val() || 0;

        const handler = (response: any): any => {
            //crear cookie y sesion
            if (!response) return null;
            let afiliado = response;

            if (sessionStorage.getItem('afiliado'))
                sessionStorage.clear();

            sessionStorage.setItem('afiliado', JSON.stringify(afiliado))
            sessionStorage.setItem('token', "")
            //set cookie
            document.cookie = "token=;"
            document.cookie = "identificacion=" + afiliado.Cedula + ";"
            window.location.href = "indexAfiliados.html"
        }
        let request = { Cedula: cedula, Contrasena: contrasena }

        this.afiliadoRepositorio.iniciarSesion(request, handler)
    }

    async revisarSesion() {
        let afiliado = JSON.parse(sessionStorage.getItem('afiliado'))
        if (!afiliado) window.location.href = "loginAfiliados.html"
        this.enlazar.enlazarObjetoValor("NombreAfiliadoEtiqueta", afiliado,".")

    }


    async enlazarDatosAfiliado() {
        let afiliado = JSON.parse(sessionStorage.getItem('afiliado'))
        this.enlazar.enlazarObjetoInput(afiliado)

        $("input").each(function () {
            $(this).attr("disabled", "disabled")
        })
    }

    async enlazarPlanesAfiliado(afiliado: any, source = "planes", destino = "planes") {
        if (!afiliado) afiliado = JSON.parse(sessionStorage.getItem('afiliado'))

        const enlazar = (planes: any) => {
            planes = planes.map(function (x: any) {
                return {
                    Plan: x.Plan,
                    FechaInicioStr: x.FechaInicioStr
                }
            })
            this.enlazar.enlazarListaPlantilla(planes, source, destino)
            //enlazar planes
        }
        await this.afiliadoRepositorio.obtenerDatosPlanes(afiliado.IdAfiliado, enlazar)
    }

    async editar() {
        $(".editable").each(function () {
            $(this).removeAttr("disabled")
            $(this).addClass("editando")
        })
        $("#botonEditar").attr("style", "display:none")
        $("#botonGuardar").removeAttr("style")
    }

    async guardar() {
        let afiliado = this.enlazar.enlazarInputObjetoPorClase("editable") as any
        afiliado.Cedula = $("#Cedula").val()
        afiliado.IdAfiliado = $("#IdAfiliado").val()
        //enviar al servidor

        function handler(response: any) {
            $(".editable").each(function () {
                $(this).attr("disabled", "disabled")
                $(this).removeClass("editando")
            })

            alerta(response)

            $("#botonGuardar").attr("style", "display:none")
            $("#botonEditar").removeAttr("style")
        }

        await this.afiliadoRepositorio.guardarAfiliado(afiliado, handler)
    }


    async  enlazarDatosNucleo() {
        let afiliado = JSON.parse(sessionStorage.getItem('afiliado'))

        //enlazar tabs
        const enlazar = (nucleo: any) => {
            let nombres = nucleo.map((x: any) => {
                return {
                    NombreAfiliado: x.NombreAfiliado,
                    IdAfiliado: x.IdAfiliado
                }
            })
            this.enlazar.enlazarListaPlantilla(nombres, "plantillaTab", "tabs")

            let li = $("#tabs").children()[0]
            let a = $(li).children()[0]
            $(a).addClass("tab-active")
        }
        await this.afiliadoRepositorio.obtenerNucleoFamiliar(afiliado.Cedula, enlazar)
    }

    async  mostrarAfiliado(element: any, afiliadoId: any) {

        const enlazar = (afiliado: any) => {
            if (afiliado.NombreAfiliado) {
                this.enlazar.enlazarObjetoInput(afiliado)
                this.enlazarPlanesAfiliado(afiliado, "plantillaPlanes", "planes")
                $(".tab-active").removeClass("tab-active")
                $(element).addClass("tab-active")

                $("input").each(function () {
                    $(this).attr("disabled", "disabled")
                })

            }
        }
        await this.afiliadoRepositorio.obtenerDatosAfiliado(afiliadoId, enlazar)

        //enlazar plan
    }

    async  mostrarAfiliadosPorPromotor() {
        let promotorId: number = JSON.parse(sessionStorage.getItem('promotor')).Id
        const enlazar = (Afiliados: any) => {
            Afiliados = Afiliados.map((a: any) => {
                return {
                    Id: a.IdAfiliado,
                    Nombre: a.NombreAfiliado,
                    Estatus: a.EstatusAfiliado,
                    PlanPrincipal: a.PlanPrincipal,
                    PlanComplementario: a.PlanComplementario,
                    FechaEntrada: a.FechaInicioVigenciaPlanStr
                }
            })
            $(".dataTables_length").empty()
            $(".dataTables_filter").empty()
            $(".dataTables_info").empty()
            $(".dataTables_paginate").empty()

            $('#TablaAfiliados').DataTable({

                "language": {
                    "sProcessing": "Procesando...",
                    "sLengthMenu": "Mostrar _MENU_ registros",
                    "sZeroRecords": "No se encontraron resultados",
                    "sEmptyTable": "Ningún dato disponible en esta tabla",
                    "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
                    "sInfoPostFix": "",
                    "sSearch": "Buscar:",
                    "sUrl": "",
                    "sInfoThousands": ",",
                    "sLoadingRecords": "Cargando...",
                    "oPaginate": {
                        "sFirst": "Primero",
                        "sLast": "Último",
                        "sNext": "Siguiente",
                        "sPrevious": "Anterior"
                    },
                    "oAria": {
                        "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
                        "sSortDescending": ": Activar para ordenar la columna de manera descendente"
                    }
                },
                data: Afiliados,
                columns: [
                    { data: 'Id' },
                    { data: 'Nombre' },
                    { data: 'Estatus' },
                    { data: 'PlanPrincipal' },
                    { data: 'PlanComplementario' },
                    { data: 'FechaEntrada' }
                ]
            });
            HideLoader()
        }
        await this.afiliadoRepositorio.obtenerDatosAfiliadoPorPromotor(promotorId, enlazar)
    }


    async mostrarConsumo(element: string, afiliadoId: any) {
        const enlazar = (consumo: any) => {
            if (consumo) {
                const consumoInmutable = consumo

                let procentajeAcc = ((consumo.ConsumidoAccidenteTransito / consumo.TotalAccidenteDeTransito) * 100).toFixed(2)
                let procentajeDisponibleAcc = ((consumo.DisponibleAccidenteTransito / consumo.TotalAccidenteDeTransito) * 100).toFixed(2)
                let procentajeFarmacia = ((consumo.ConsumoFarmacia / consumo.TotalFarmacia) * 100).toFixed(2)
                let procentajeDisponibleFarmacia = ((consumo.DisponibleFarmacia / consumo.TotalFarmacia) * 100).toFixed(2)

                consumo.ConsumidoAccidenteTransito = "Consumido: " + formatNumber(consumo.ConsumidoAccidenteTransito)
                consumo.DisponibleAccidenteTransito = "Disponible: " + formatNumber(consumo.DisponibleAccidenteTransito)
                consumo.ConsumoFarmacia = "Consumido: " + formatNumber(consumo.ConsumoFarmacia)
                consumo.DisponibleFarmacia = "Disponible: " + formatNumber(consumo.DisponibleFarmacia)
                consumo.ConsumoTotalRiesgo = "Consumido: " + formatNumber(consumo.ConsumoTotalRiesgo)
                consumo.DisponibleTotalRiesgo = "Disponible: " + formatNumber(consumo.DisponibleTotalRiesgo)

                //enlazar progress bar

                $("#progressAcc1").attr("style", `width:${procentajeAcc}%`)
                $("#progressAcc2").attr("style", `width:${procentajeDisponibleAcc}%`)
                $("#progressFar1").attr("style", `width:${procentajeFarmacia}%`)
                $("#progressFar2").attr("style", `width:${procentajeDisponibleFarmacia}%`)

                this.enlazar.enlazarObjetoValor("consumo", consumo)
                $(".tab-active").removeClass("tab-active")
                $(element).addClass("tab-active")
                //calcular progress bar
                // consumo.porcentajeAccidente = ((consumo.ConsumidoAccidenteTransito / 
                //                                 consumo.TotalAccidenteDeTransito)*100).toFixed(0)
                // $("#ConsumidoAccidenteTransito").css({ width: consumo.porcentajeAccidente + '%' })

                this.mostrarConsumoAltoCosto(afiliadoId)

                this.mostrarAutorizaciones(afiliadoId)

                if (!element) {
                    let li = $("#tabs").children()[0]
                    let a = $(li).children()[0]
                    $(a).addClass("tab-active")
                }
            }
        }

        await this.afiliadoRepositorio.obtenerDatosConsumo(afiliadoId, enlazar)
    }

    async mostrarConsumoAltoCosto(afiliadoId: number) {
        const enlazar = (consumo: any) => {
            if (consumo) {
                const consumoInmutable = consumo
                consumo.map((c: any) => {
                    c.atTotal = "Consumido: " + formatNumber(c.Consumido)
                    c.atDisponible = "Disponible: " + formatNumber(c.Disponible)
                    c.porcentajeDisponible = ((c.Disponible / c.TotalAltoCosto) * 100).toFixed(2)
                    c.porcentajeConsumido = ((c.Consumido / c.TotalAltoCosto) * 100).toFixed(2)
                    return c
                })

                this.enlazar.enlazarListaPlantilla(consumo, "plantillaAltoCosto", "altoCosto")
            }
        }
        await this.afiliadoRepositorio.obtenerDatosConsumoAltoCosto(afiliadoId, enlazar)
    }

    async  mostrarAutorizaciones(afiliadoId: number) {

        const enlazar = (Autorizaciones: any) => {
            Autorizaciones = Autorizaciones.map((a: any) => {
                return {
                    NumeroDeAutorizacion: a.NumeroDeAutorizacion,
                    PrestadorDeServicios: a.PrestadorDeServicios,
                    Origen: a.Origen,
                    Fecha: a.FechaStr,
                    TotalCubierto: formatNumber(a.TotalCubierto)
                }
            })
            $(".dataTables_length").empty()
            $(".dataTables_filter").empty()
            $(".dataTables_info").empty()
            $(".dataTables_paginate").empty()

            $('#TablaAutorizaciones').DataTable({

                "language": {
                    "sProcessing": "Procesando...",
                    "sLengthMenu": "Mostrar _MENU_ registros",
                    "sZeroRecords": "No se encontraron resultados",
                    "sEmptyTable": "Ningún dato disponible en esta tabla",
                    "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
                    "sInfoPostFix": "",
                    "sSearch": "Buscar:",
                    "sUrl": "",
                    "sInfoThousands": ",",
                    "sLoadingRecords": "Cargando...",
                    "oPaginate": {
                        "sFirst": "Primero",
                        "sLast": "Último",
                        "sNext": "Siguiente",
                        "sPrevious": "Anterior"
                    },
                    "oAria": {
                        "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
                        "sSortDescending": ": Activar para ordenar la columna de manera descendente"
                    }
                },
                data: Autorizaciones,
                columns: [
                    { data: 'NumeroDeAutorizacion' },
                    { data: 'PrestadorDeServicios' },
                    { data: 'Origen' },
                    { data: 'Fecha' },
                    { data: 'TotalCubierto' }
                ]
            });


        }

        await this.afiliadoRepositorio.obtenerDatosHistorialAutorizaciones(afiliadoId, enlazar)
    }

    async enlazarDatosConsumo() {
        let afiliado = JSON.parse(sessionStorage.getItem('afiliado'))

        //enlazar tabs
        const enlazar = (nucleo: any) => {
            let nombres = nucleo.map((x: any) => {
                return {
                    NombreAfiliado: x.NombreAfiliado,
                    IdAfiliado: x.IdAfiliado
                }
            })
            this.enlazar.enlazarListaPlantilla(nombres, "plantillaTab", "tabs")
            this.mostrarConsumo("", nombres[0].IdAfiliado)
        }
        await this.afiliadoRepositorio.obtenerNucleoFamiliar(afiliado.Cedula, enlazar)


    }

    async enlazarReembolso() {
        let afiliado = JSON.parse(sessionStorage.getItem('afiliado'))

        //enlazar tabs
        const enlazar = (nucleo: any) => {
            let nombres = nucleo.map((x: any) => {
                return {
                    NombreAfiliado: x.NombreAfiliado,
                    IdAfiliado: x.IdAfiliado,
                }
            })
            this.enlazar.enlazarListaPlantilla(nombres, "afiliadoDropDown", "IdAfiliado")
            //obtener historial

            this.afiliadoRepositorio.obtenerDatosReembolso(afiliado.Cedula, this.enlazarHistorialReembolso.bind(this))
        }
        await this.afiliadoRepositorio.obtenerNucleoFamiliar(afiliado.Cedula, enlazar)
    }

    async enlazarHistorialReembolso(historial: any) {
        if(typeof historial == "object")
            this.enlazar.enlazarListaPlantilla(historial, "PlantillaHistorialReembolso", "historialReembolso")
    }

    async enlazarHistorialPreCertificada(historial: any) {
        this.enlazar.enlazarListaPlantilla(historial, "plantillaHistorialPrecertificada", "historialPrecertificada")
    }

    
    async enlazarFacturasPendientes() {
        let afiliado = JSON.parse(sessionStorage.getItem('afiliado'))

        const handler = (facturas:any) => {

            if(typeof facturas == "object")
            {
                // SELECCIONAR LA PRIMERA MAS VIEJA
                let facturaPendiente;
                facturaPendiente = [...facturas].slice(0,1)
                this.enlazar.enlazarListaPlantilla(facturaPendiente, 
                    "plantillaFacturas", "historialFactura")
            }
            
                
        }
        
        this.afiliadoRepositorio.obtenerFacturas(afiliado.Cedula, handler)
    }

    async enlazarHistorialFacturas() {
        let afiliado = JSON.parse(sessionStorage.getItem('afiliado'))
        const handler = (facturas:any) => {
            this.enlazar.enlazarListaPlantilla(facturas, 
                "plantillaRecibos", "historialFacturaPagadas")
        }
        this.afiliadoRepositorio.obtenerFacturasPagadas(afiliado.Cedula, handler)
    }

    async enlazarHistorialFacturasPagadasPorPromotor(selector:string) {
        let promotorId: number = JSON.parse(sessionStorage.getItem('promotor')).Id
        const enlazar = (facturas: any) => {
            facturas = facturas.map((a: any) => {
                return {
                    IdFactura: a.IdFactura,
                    IdCliente: a.IdCliente,
                    TipoCliente:a.TipoCliente,
                    Cliente: a.Cliente,
                    Estatus: a.EstatusPago,
                    CoberturaDesdeHasta: `${a.CoberturaInicioStr} / ${a.CoberturaFinStr}`,
                    FechaGeneracion: a.FechaGeneracionStr,
                    FechaPago: a.FechaPagoStr,
                    NumeroAprobacionTarjeta: a.NumeroAprobacionTarjeta,
                    Vencimiento: a.VencimientoStr,
                    Monto: a.MontoStr,
                    recibo:`<td><a class="botonf" href="${a.recibo}" title="Ver Recibo" target="blank">Recibo</a></td>`
                }
            })
            $(`#${selector} > .dataTables_length`).empty()
            $(`#${selector} >.dataTables_filter`).empty()
            $(`#${selector} >.dataTables_info`).empty()
            $(`#${selector} >.dataTables_paginate`).empty()
            if(!$.fn.DataTable.isDataTable( $(`#${selector}`) )){
                $(`#${selector}`).DataTable({

                    "language": {
                        "sProcessing": "Procesando...",
                        "sLengthMenu": "Mostrar _MENU_ registros",
                        "sZeroRecords": "No se encontraron resultados",
                        "sEmptyTable": "Ningún dato disponible en esta tabla",
                        "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                        "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                        "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
                        "sInfoPostFix": "",
                        "sSearch": "Buscar:",
                        "sUrl": "",
                        "sInfoThousands": ",",
                        "sLoadingRecords": "Cargando...",
                        "oPaginate": {
                            "sFirst": "Primero",
                            "sLast": "Último",
                            "sNext": "Siguiente",
                            "sPrevious": "Anterior"
                        },
                        "oAria": {
                            "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
                            "sSortDescending": ": Activar para ordenar la columna de manera descendente"
                        }
                    },
                    data: facturas,
                    columns: [
                        { data: 'IdFactura' },
                        { data: 'TipoCliente' },
                        { data: 'Cliente' },
                        { data: 'FechaGeneracion' },
                        { data: 'Vencimiento' },
                        { data: 'CoberturaDesdeHasta' },
                        { data: 'Monto' },
                        { data: 'Estatus' },
                        {data: 'recibo'}
                    ],
                    order: [],
                    pageLength:5,
                    lengthMenu: [5, 10, 25, 50]
                });
            }
            HideLoader()
        }
        await this.afiliadoRepositorio.obtenerFacturasPorPromotor(promotorId,EnumEstadoFactura.PAGADA, enlazar)
    }

    async enlazarHistorialFacturasPendientesPorPromotor(selector:string) {
    
        let promotorId: number = JSON.parse(sessionStorage.getItem('promotor')).Id
        const enlazar = (facturas: any) => {
            facturas = facturas.map((a: any) => {
                return {
                    IdFactura: a.IdFactura,
                    IdCliente: a.IdCliente,
                    TipoCliente:a.TipoCliente,
                    Cliente: a.Cliente,
                    Estatus: a.EstatusPago,
                    CoberturaDesdeHasta: `${a.CoberturaInicioStr} / ${a.CoberturaFinStr}`,
                    FechaGeneracion: a.FechaGeneracionStr,
                    FechaPago: a.FechaPagoStr,
                    NumeroAprobacionTarjeta: a.NumeroAprobacionTarjeta,
                    Vencimiento: a.VencimientoStr,
                    Monto: a.MontoStr,
                    factura:`<td><a class="botonf" target="_blank" href="${a.factura}">Pagar</a></td>`
                }
            })
            // SELECCIONAR LA PRIMERA MAS VIEJA DE CADA CLIENTE
            let facturaPendiente=[];
            for(let factura of facturas){
                if(facturaPendiente.filter(a=>a.IdCliente == factura.IdCliente).length == 0)
                    facturaPendiente.push(factura)
            }
            
            $(`#${selector} > .dataTables_length`).empty()
            $(`#${selector} >.dataTables_filter`).empty()
            $(`#${selector} >.dataTables_info`).empty()
            $(`#${selector} >.dataTables_paginate`).empty()
            if(!$.fn.DataTable.isDataTable( $(`#${selector}`) )){
                $(`#${selector}`).DataTable({

                    "language": {
                        "sProcessing": "Procesando...",
                        "sLengthMenu": "Mostrar _MENU_ registros",
                        "sZeroRecords": "No se encontraron resultados",
                        "sEmptyTable": "Ningún dato disponible en esta tabla",
                        "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                        "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                        "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
                        "sInfoPostFix": "",
                        "sSearch": "Buscar:",
                        "sUrl": "",
                        "sInfoThousands": ",",
                        "sLoadingRecords": "Cargando...",
                        "oPaginate": {
                            "sFirst": "Primero",
                            "sLast": "Último",
                            "sNext": "Siguiente",
                            "sPrevious": "Anterior"
                        },
                        "oAria": {
                            "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
                            "sSortDescending": ": Activar para ordenar la columna de manera descendente"
                        }
                    },
                    data: facturaPendiente,
                    columns: [
                        { data: 'IdFactura' },
                        { data: 'TipoCliente' },
                        { data: 'Cliente' },
                        { data: 'FechaGeneracion' },
                        { data: 'Vencimiento' },
                        { data: 'CoberturaDesdeHasta' },
                        { data: 'Monto' },
                        { data: 'Estatus' },
                        {data: 'factura'}
                    ],
                    pageLength:5,
                    lengthMenu: [5, 10, 25, 50],
                });
            }
            HideLoader()
        }
        await this.afiliadoRepositorio.obtenerFacturasPorPromotor(promotorId,EnumEstadoFactura.PENDIENTE_PAGO, enlazar)
    }
    
async validarYGuardarReembolso() {
    let r:any = this.enlazar.enlazarInputObjeto(new Reembolso())
    r.NombreAfiliado = $("#IdAfiliado option:selected").text();
    let camposValidar:any = ["IdAfiliado", "NombrePss", "RazonSolicitud", "FechaServicioStr",
        "MontoPagado", "FormaPago", "NumeroCuenta", "TipoCuenta", "Banco", "RP", "CAR","CC"]
    let camposInvalidos = this.validador.validarCampos(r, camposValidar)

    //si no hay campos invalidos proceder
    if (camposInvalidos.length == 0) {

        this.afiliadoRepositorio.guardarReembolso(r, this.manejarRespuestaGuardarReembolso.bind(this))
    } else {
        alerta("Debe llenar los campos marcados en rojo")
    }

}

async manejarRespuestaGuardarReembolso(response:any) {
    
        let promises:any = []
    
        promises.push(this.afiliadoRepositorio.guardarImagenReembolso(response.IdSolicitudReembolso, response.IdAfiliado, "RP"))
        promises.push(this.afiliadoRepositorio.guardarImagenReembolso(response.IdSolicitudReembolso, response.IdAfiliado, "CAR"))
        // if ($("#CedulaPic").val())
            promises.push(this.afiliadoRepositorio.guardarImagenReembolso(response.IdSolicitudReembolso, response.IdAfiliado, "CC"))
    
        let responses = await Promise.all(promises)
    
        if (responses.some(function (v:any) { return v == false }))
            alerta("Error al enviar el reembolso")
        else {
            let afiliado = JSON.parse(sessionStorage.getItem('afiliado'))
            alerta("Reembolso guardado")
            this.limpiarReembolso()
            this.afiliadoRepositorio.obtenerDatosReembolso(afiliado.Cedula, this.enlazarHistorialReembolso)
        }
    }
    limpiarReembolso() {
        this.enlazar.enlazarObjetoInput(new Reembolso())
    }
    
    async enlazarPreCertificada() {
        let afiliado = JSON.parse(sessionStorage.getItem('afiliado'))
    
        //enlazar tabs
        const enlazar = (nucleo:any) => {
            let nombres = nucleo.map((x:any) => {
                return {
                    NombreAfiliado: x.NombreAfiliado,
                    IdAfiliado: x.IdAfiliado,
                }
            })
            this.enlazar.enlazarListaPlantilla(nombres, "afiliadoDropDown", "IdAfiliado")

            //obtener historial
    
            this.afiliadoRepositorio.obtenerDatosPreCertificadas(afiliado.Cedula, this.enlazarHistorialPreCertificada.bind(this))
        }
        await this.afiliadoRepositorio.obtenerNucleoFamiliar(afiliado.Cedula, enlazar)
    }

    async  validarYGuardarPreCertificada() {
        let p = this.enlazar.enlazarInputObjeto(new PreCertificada())
    
        let camposValidar:any = ["IdAfiliado", "NombrePss", "RazonSolicitud", "FechaServicio",
            "Telefono", "CAR", "IM", "HC", "OSC"]
        let camposInvalidos = this.validador.validarCampos(p, camposValidar)
    
        //si no hay campos invalidos proceder
        if (camposInvalidos.length == 0) {
    
            this.afiliadoRepositorio.guardarPreCertificada(p, this.manejarRespuestaPreCertificada.bind(this))
        } else {
            alerta("Debe llenar los campos marcados en rojo")
        }
    }

    async manejarRespuestaPreCertificada(response:any) {
        
            let promises:any = []
        
            promises.push(this.afiliadoRepositorio.guardarImagenPreCertificada(response.IdSolicitudPrecertificada, response.IdAfiliado, "CAR"))
            promises.push(this.afiliadoRepositorio.guardarImagenPreCertificada(response.IdSolicitudPrecertificada, response.IdAfiliado, "IM"))
            promises.push(this.afiliadoRepositorio.guardarImagenPreCertificada(response.IdSolicitudPrecertificada, response.IdAfiliado, "HC"))
            promises.push(this.afiliadoRepositorio.guardarImagenPreCertificada(response.IdSolicitudPrecertificada, response.IdAfiliado, "OSC"))
            // if ($("#CedulaPic").val())
                promises.push(this.afiliadoRepositorio.guardarImagenPreCertificada(response.IdSolicitudPrecertificada, response.IdAfiliado, "CC"))
        
            let responses = await Promise.all(promises)
        
            if (responses.some(function (v:any) { return v == false }))
                alerta("Error al enviar el reembolso")
            else {
                let afiliado = JSON.parse(sessionStorage.getItem('afiliado'))
                alerta("Autorizacion pre-certificada guardada")
                this.enlazar.enlazarObjetoInput(new PreCertificada())
            }
        }

        async enlazarFacturaCabecera(idfactura:number) {

            const handler = (factura:any) => {
                factura.Monto = formatNumber(factura.Monto)
                $("#Amount").val(factura.Monto.replace(".","").replace(",",""));
                $("#CustomField1Value").val(factura.Cliente);
                $("#CustomField2Value").val(factura.VencimientoStr);
                this.enlazar.enlazarObjetoInput(factura)
            }        
            this.afiliadoRepositorio.obtenerFacturaCabecera(idfactura, handler)
            this.enlazarDetalleFactura(idfactura)
        }


        
    async enlazarDetalleFactura(idfactura: number) {
  
        let TotalFactura:number= 0
        const enlazar = (detalleFacturas: any) => {
            detalleFacturas = detalleFacturas.map(function (x: any) {
                return {
                    Descripcion: x.Descripcion,
                    Totaldetalle: formatNumber(x.Totaldetalle)
                }
            })
            this.enlazar.enlazarListaPlantilla(detalleFacturas, "plantillaDetalleFacturas", "DetalleFactura")
         }
        await this.afiliadoRepositorio.obtenerFacturaDetalle(idfactura, enlazar)
    }


    
    async TransaccionAzulGuardar() {
        let transaccionAzul:any = this.enlazar.enlazarInputObjeto(new TransaccionAzul())
 
        await this.afiliadoRepositorio.GuardarTransaccionAzul(transaccionAzul)
    }
 
    async solicitudReestablecerContrasena(){
        let modelo = this.enlazar.enlazarInputObjeto(new reestablecerContrasenaAfiliado()) 
        const campos =["cedula","nss","afiliadoId","correo"]
        let camposInvalidos = this.validador.validarCampos(modelo, campos)
        
         if(camposInvalidos.length == 0){
             this.afiliadoRepositorio.recuperarContrasena(modelo)
         }else{
            alerta("Debe llenar los campos marcados en rojo")
         }
    }

    async cerrarSesion(){
        clearStorageCookies()
    }


}