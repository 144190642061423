
declare var Object:any
declare var $:any

export default class Enlazar{
        constructor()
        {}
      enlazarObjetoInput(obj) {
        //enlazar valores del objeto al input
        Object.keys(obj).forEach(function (key) {
            $(`#${key}`).val(obj[key])
        })
    
    }
    
      enlazarInputObjeto(obj) {
        let o = {}
        Object.keys(obj).forEach(function (key) {
            o[key] = $(`#${key}`).val()
    
            if (!o[key]) o[key] = ""
        })
        return o;
    }
    
      enlazarInputObjetoPorClase(clase) {
        var obj = {}
        $("." + clase).each(function () {
            obj[this.id] = $(this).val()
        })
        return obj;
    }
    enlazarInputFilePorClase(clase:string) {
        var arr:Array<Object>= new Array<Object>();
        $("." + clase).each(function () {
            var obj = 
            {
                IdTipoDoc: $(this).data("id"),
                File: this.files[0]
            }
            if(obj.File)
                arr.push(obj);
        })
        return arr;
    }

    
    enlazarInputFilePorClase2(clase:string) {
        var FileList:Array<Object> = new Array<Object>();;
        $("." + clase).each(function (key:number) {
            if(this.files[0])
                FileList.push(this.files);
        })
        return FileList;
    }
    enlazarInputFilePorClase3(clase:string) {
        var FileList:Array<Object> = new Array<Object>();;
        $("." + clase).each(function (key:number) {
            if(this.files[0])
                FileList.push(this.files[0]);
        })
        return FileList;
    }
    
      enlazarObjetoValor(idPadre:any, obj:any,selectorType:string="#") {
        //enlazar valores del objeto al input
        let index = 0
        Object.keys(obj).forEach(function (key:any) {
            var html = $(`${selectorType}${idPadre}`).html();
    
            html = html.replace(`{${key}}`, obj[key])
    
            $(selectorType + idPadre).html(html);
            index++
        })
    
        Object.keys(obj).forEach(function (key:any) {
            $(`span${selectorType}${key}`).html(obj[key]);
            $(`div${selectorType}${key}`).html(obj[key]);
        })
    }
    
    
      limpiarObjecto(obj) {
        Object.keys(obj).forEach(function (key) {
            obj[key] = ''
        })
    
        return obj
    }
    
    ///funcion que toma un Id de un nodo padre obtiene e html hijo
    ///y utilia la lista para replicar y enlazar dicho elemento
      enlazarListaPlantilla(lista, fuente, destino) {
        //enlazar valores del objeto al input
        let plantilla = $("#" + fuente).html();
        $("#" + destino).html('');
        let index = 0
        let listaEnlazada = ""
    
        //creear plantilla, enlazar valores
        lista = lista.map( (obj:any) => {
            let objCambiado = {} as any
            let keyAnt = ""
            Object.keys(obj).forEach((key:any) =>{
                objCambiado[`${key}_${index}`] = obj[key]
    
                if (index == 0) {
                    plantilla = plantilla.replace(`id="${key}"`, `id="${key}_${index}"`)
    
                    plantilla = plantilla.replace(`{${key}}`, `{${key}_${index}}`)
                } else {
                    plantilla = plantilla.replace(`id="${key}_${index - 1}"`, `id="${key}_${index}"`)
                    plantilla = plantilla.replace(`{${key}_${index - 1}}`, `{${key}_${index}}`)
                }
                plantilla = plantilla.replace("<table>", "")
                plantilla = plantilla.replace("</table>", "")
                plantilla = plantilla.replace("<tbody>", "")
                plantilla = plantilla.replace("</tbody>", "")
            })
    
            //inserto la nueva plantilla
            $("#" + destino).append(plantilla);
    
            this.enlazarObjetoValor(destino, objCambiado)
            //enlazarObjetoInput(objCambiado)
            index++;
            return objCambiado;
        })
        // enlazar inputs
    
        lista.forEach( (obj:any) => {
            this.enlazarObjetoInput(obj)
        })
    
    }   

    
}