import AfiliadoControlador from './controladores/AfiliadoControlador'
import PrestadorControlador from './controladores/PrestadorControlador';
import ContactoController from './controladores/ContactoController'
import IndexControlador from './controladores/indexControlador';
import PromotorControlador from './controladores/PromotorControlador'
declare var window: any

window.afiliadoControlador = new AfiliadoControlador()
window.prestadorControlador = new PrestadorControlador()
window.contactoController = new ContactoController()
window.indexControlador = new IndexControlador()
window.promotorControlador = new PromotorControlador()