import HttpClient from '../servicios/HttpClient'
import { baseURl, alerta, defaultHttpErrorHandler,SwalType } from '../utilidades/SinClasificar'

export default class GeneralRepositorio{
    httpClient:HttpClient
    constructor(){
        this.httpClient = new HttpClient();
    }

    async obtenerDatosByCedula(Cedula:string="",handler:any){
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }
        let url = baseURl + `api/General/ConsultarPadron/Cedula/${Cedula}`;
        await this.httpClient.getJson(url, success, defaultHttpErrorHandler)
    }
    async obtenerDatosByRNC(RNC:string="",handler:any){
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }
        let url = baseURl + `api/General/ConsultarPadron/RNC/${RNC}`;
        await this.httpClient.getJson(url, success, defaultHttpErrorHandler)
    }
    async obtenerRegiones(handler:any){
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }
        let url = baseURl + `api/General/Regiones/`;
        await this.httpClient.getJson(url, success, defaultHttpErrorHandler)
    }
    async obtenerProvincias(handler:any,IdAreaSalud:number=null){
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }

        let url="";
        if(IdAreaSalud)
            url = baseURl + `api/General/Provincias/${IdAreaSalud}`;
        else
            url = baseURl + "api/General/Provincias";
        await this.httpClient.getJson(url, success, defaultHttpErrorHandler)
    }
    async obtenerMunicipios(IdProvincia:number=null,handler:any){
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }
        let url="";
        if(IdProvincia)
            url = baseURl + `api/General/Municipios/${IdProvincia}`;
        else
            url = baseURl + "api/General/Municipios/";

        await this.httpClient.getJson(url, success, defaultHttpErrorHandler)
    }
    

    async obtenerTiposPss(handler:any,IdClasificacion:number){
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }
        let url = "";
        if(IdClasificacion)
            url = baseURl + `api/General/TiposPss/${IdClasificacion}`;
        else
            url = baseURl + "api/General/TiposPss";
        await this.httpClient.getJson(url, success, defaultHttpErrorHandler)
    }
    async obtenerSexo(handler:any){
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }
        let url = baseURl + `api/General/Sexo/`;
        await this.httpClient.getJson(url, success, defaultHttpErrorHandler)
    }
    async obtenerNacionalidades(handler:any){
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }
        let url = baseURl + `api/General/Nacionalidades/`;
        await this.httpClient.getJson(url, success, defaultHttpErrorHandler)
    }
    async obtenerEstadosCiviles(handler:any){
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }
        let url = baseURl + `api/General/EstadosCiviles/`;
        await this.httpClient.getJson(url, success, defaultHttpErrorHandler)
    }
    async obtenerTitulos(handler:any){
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }
        let url = baseURl + `api/General/Tratamientos/`;
        await this.httpClient.getJson(url, success, defaultHttpErrorHandler)
    }
}