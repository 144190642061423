export default class MedicoEspecialidad  {
    IdMedicoEspecialidad:number=null;
    IdEspecialidad:number=null;
    IdSolicitudMedico:number=null;
    Tipo:TipoEspecialidad=TipoEspecialidad.PRIMARIA;
}
enum TipoEspecialidad {
    PRIMARIA = 1,
    SECUNDARIA = 2
}
