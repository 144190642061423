import HttpClient from '../servicios/HttpClient'
import Contacto from '../modelos/contacto'
import { baseURl,SwalType,alerta} from '../utilidades/SinClasificar'




var error = (response: any) => {
    console.log(response)
    alerta("Se ha producido un error inesperado",null,SwalType.ERROR)
}

export default class ContactoRepositorio{
    constructor(){
        this.httpClient = new HttpClient()
    }

    httpClient:HttpClient

    async guardarDatosContacto(handler:(res:any)=> void, datos:Contacto)
    {
        let url = baseURl + `api/General/Contacto/`;
        
        await this.httpClient.postJson(url, datos, handler, error)
    }
}