import HttpClient from '../servicios/HttpClient'
import LoginPrestador from '../modelos/prestadorLogin'
import { baseURl, alerta, defaultHttpErrorHandler,SwalType } from '../utilidades/SinClasificar'
import registroPrestador from '../modelos/registroPrestador'
import GuardarPrecioPss from '../modelos/GuardarPrecioPss';
import { PrestadorModeloConsulta } from '../modelos/PrestadorModeloConsulta';
import PrestadorContrasena from '../modelos/prestadorContransena';
import Centro from '../modelos/Centro';
import Medico from '../modelos/Medico';
import CentroView from '../modelos/CentroView';
import EnumTipoSolicitudPSS from '../modelos/EnumTipoSolicitud';

var error = (response: any) => {
    console.log(response)
    alerta("Se ha producido un error inesperado!",null,SwalType.ERROR)
}

declare var swal: any
declare var JSON: any
declare var HideLoader: any
declare var ShowLoader: any

  class PrestadorRepositorio {
    httpClient: HttpClient
    constructor() {
        this.httpClient = new HttpClient();
    }

    iniciarSesion(model: LoginPrestador, handler: any) {
        const action = baseURl + "api/pss/InicioSesion"
        this.httpClient.postJson(action, model, handler, defaultHttpErrorHandler)
    }

    registrar(model: registroPrestador, handler: (res: any) => void) {
        const action = baseURl + "api/pss/"
        this.httpClient.postJson(action, model, handler, defaultHttpErrorHandler)
    }

    obtenerPrestador(rnc: any, handler: any) {
        const action = baseURl + `api/pss/${rnc}`
        this.httpClient.getJson(action, handler, defaultHttpErrorHandler)
    }

    obtenerPrestadorConsulta(modelo: PrestadorModeloConsulta, handler: (respose: any) => void) {

        if (modelo.Nombre =="" ||modelo.Nombre ==null) modelo.Nombre = "null"
        const action = baseURl + `api/pss/ObtenerPSSConsulta/${modelo.Nombre}/${modelo.IdTipo}/${modelo.IdProvincia}`
        this.httpClient.getJson(action, handler, defaultHttpErrorHandler)
    }

    obtenerPrestadorTipos(handler: (respose: any) => void) {

        const action = baseURl + `api/pss/ObtenerPSSConsulta/`
        this.httpClient.getJson(action, handler, defaultHttpErrorHandler)
    }

    async obtenerTarifaPrestador(IdPrestador:number, handler: (respose: any) => void) {
        const action =  baseURl +`api/pss/ObtenerPreTarifa/${IdPrestador}`
        //const action =  `https://localhost:52982/api/pss/ObtenerPreTarifa/${IdPrestador}`
        
        await this.httpClient.getJson(action, handler, defaultHttpErrorHandler)
    }

    async guardarPrecioProcedmiento(procedimiento:GuardarPrecioPss, handler:any){
        const action = baseURl + `api/pss/CambiarPrecioPss/`
        //const action = `https://localhost:52982/api/pss/CambiarPrecioPss/`
        await this.httpClient.postJson(action,procedimiento, handler, defaultHttpErrorHandler)
    }

    async EnviarNotificacionNuevosCambiosTarifa(IdPrestador:number, handler:any){
        const action = baseURl + `api/pss/EnviarNotificacionNuevosCambios/${IdPrestador}`;
        //const action = `https://localhost:52982/api/pss/EnviarNotificacionNuevosCambios/${IdPrestador}`;
        await this.httpClient.getJson(action, handler, defaultHttpErrorHandler)
    }

    cambiarContrasena(model: PrestadorContrasena, handler: any) {
        const action = baseURl + `api/pss/CambiarCredenciales/`
        this.httpClient.postJson(action, model, handler, defaultHttpErrorHandler)
    }

    obtenerHistorialReclamaciones(RncCedula: string, handler: (respose: any) => void) {

        const action = baseURl + `api/pss/ObtenerHistorialSolicitudesPss/${RncCedula}`
        this.httpClient.getJson(action, handler, defaultHttpErrorHandler)
    }

    guardarPss(pss:any, handler:any){
        const action = baseURl + `api/pss/CambiarDatosContactos/`
        this.httpClient.postJson(action,pss, handler, defaultHttpErrorHandler)
    }

    async recuperarContrasena(modelo: any) {
        const success = function (response: any) {
            alerta("Solicitud de recuperacion de contraseña enviada",null,SwalType.SUCCESS)
                .then(() => {
                    window.location.href = "/loginPrestadores.html"
                })

        }

        let url = baseURl + `api/pss/Recuperar/${modelo.RncoCedula.trim()}/${modelo.CedulaRepresentante.trim()}/${modelo.Correo.trim()}/${modelo.IdPss.trim()}`;
        this.httpClient.getJson(url, success, defaultHttpErrorHandler);
    }

    async guardarLoteWebsite(p: any, handler: any) {
        ShowLoader();
        const success = function (response: any) {
            if (response) {
                if (handler) handler(response)
            }
        }
        let url = baseURl + "api/Reclamaciones/RegistrarCargaLoteReclamaciones/";

        await this.httpClient.postJson(url, p, success, error) 
    }

    async  guardarArchivoLote(IdLoteWebSite: any, IdPss: any) {
        let result = false
        const success = function (response: any) {
            result = true
        }
        let file = (document.getElementById("Lote") as any).files[0]

        let url = baseURl + `api/imagenes/lote/${IdLoteWebSite}/${IdPss}`;

        const e = function () {
            console.log(e)
        }

        await this.httpClient.postFile(url, file, success, e)
        return result
    }

    async obtenerDatosPssId(IdPSs:number, handler:any) {
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }

        let url = baseURl + 'api/pss/buscarporId/' + IdPSs
        await this.httpClient.getJson(url, success, error)
    }

    
    async obtenerDatosPssRnc(RncoCedula:string, handler:any) {
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }

        let url = baseURl + 'api/pss/' + RncoCedula
        await this.httpClient.getJson(url, success, error)
    }

    async obtenerEspecialidades(handler:any){
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }
        let url = baseURl + `api/pss/medico/especialidades`;
        await this.httpClient.getJson(url, success, defaultHttpErrorHandler)
    }
    async obtenerClasificaciones(handler:any){
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }
        let url = baseURl + `api/pss/clasificaciones`;
        await this.httpClient.getJson(url, success, defaultHttpErrorHandler)
    }
    async obtenerCentrosMedicos(model:CentroView, handler:any){
        const success = function (response:any) {
            if (response) {
                handler(response)
            }
        }
        let url="";
        if(model.NombreMedicoCentro && model.NombreMedicoCentro!="")
           url = baseURl + `api/pss/medico/centrosmedicos/nombre/${model.NombreMedicoCentro}/clasificacion/${model.TipoCentro}`;
        await this.httpClient.getJson(url, success, defaultHttpErrorHandler)
    }
    GuardarPssMedico(model: Medico, handler: (response: any) => void) {
        const success = function (res: Medico) {
            if (res) {
                if (handler) handler(res)
            }
        }
        const action = baseURl + "api/pss/registro/medico"
        this.httpClient.postJson(action, model, success, defaultHttpErrorHandler)
    }

    async  GuardarArchivoSolicitudPSS(response:any,docs:any) {
        let result = false
        const success = function (response: any) {
            result = true
        }
        for(let file of docs){
            let url = baseURl + `api/pss/documentos/${response.TipoSol}/${response.IdSolicitud}/${file.IdTipoDoc}`;

            const e = function () {
                console.log(e)
            }
            await this.httpClient.postFile(url, file.File, success, e)
        }
       
        return result
    }
    async  EnviarMailRecepcionSolicitudPSS(response:any) {
        let result = false
        const success = function (response: any) {
            result = true
        }
     
            let url = baseURl + `api/pss/email/${response.TipoSol}/${response.IdSolicitud}`;

            const e = function () {
                console.log(e)
            }
            await this.httpClient.postJson(url,null, success, e)
       
        return result
    }
    GuardarPssCentro(model: Centro, handler: any) {
        const success = function (res: any) {
            if (res) {
                if (handler) handler(res)
            }
        }
        const action = baseURl + "api/pss/registro/centro"
        this.httpClient.postJson(action, model, success, defaultHttpErrorHandler)
    }

}

export default PrestadorRepositorio