import Persona from "./Persona";

export default class Representante extends Persona  {

    IdRepresentante:number;
    IdTitulo:number;
    Ocupacion:string="";
    Direccion:string="";
    IdCiudad:number;
    IdSector:number;
}