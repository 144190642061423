import TelefonoMedico from '../modelos/TelefonoMedico';
import MedicoEspecialidad from '../modelos/MedicoEspecialidad';
import Persona from './Persona';
import MedicoCentro from './MedicoCentro';
export default class Medico extends Persona  {
    constructor(){
        super();
        this.Especialidades = new MedicoEspecialidad();
        this.Centros = new Array<MedicoCentro>();
        this.Telefonos = new Array<TelefonoMedico>();
    }
    IdSolicitudMedico:number;
    Exequatur:string="";
    IdClasificacion:number=null;
    Correo:string="";
    Estatus:string=null;
    Especialidades:MedicoEspecialidad = new MedicoEspecialidad();
    Telefonos:Array<TelefonoMedico> = new Array<TelefonoMedico>();
    Centros:Array<MedicoCentro>=new Array<MedicoCentro>();
 
}
